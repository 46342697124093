import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: { 
    padding: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0)
    },
   },
  tabContent: { 
    padding: theme.spacing(4),
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0)
    },
   },
  ratesTab: {
    background: theme.palette.primary.main,
    color: "#fff"
  }
}));
