import React, { useEffect, useState, useCallback } from "react";
import Dropdown from "../../../components/Dropdowns/MultiSelect";
import SingleSelectDropdown from "../../../components/Dropdowns/SingleSelect";
import { useStyles } from "./styles";
import clsx from "clsx";
import { useSelector } from "react-redux";
import AccessGroupService from "../../../services/AccessGroupService";
import ContractService from "../../../services/ContractService";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { Button } from "@material-ui/core";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import _ from "lodash";

const accessGroupService = new AccessGroupService(apiClient);
const contractService = new ContractService(apiClient);

const StatusSyncPage = () => {
  const classes = useStyles();
  const facilityID = useSelector((state) => state.entities.ContextID);
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { reasonConfirmation } = useConfirmationDialog();
  const [accessGroups, setAccessGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([{}]);
  const [affectedHolders, setAffectedHolders] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");

  const availableStatuses = [
    { value: "", name: "" },
    { value: "in", name: "In" },
    { value: "out", name: "Out" },
    { value: "neutral", name: "Neutral" },
  ];

  useEffect(() => {
    accessGroupService
      .GetAccessGroups(scopeAwareFacilityID)
      .then((result) => {
        setAccessGroups(_.orderBy(result.data?.collection, [ "name", "asc" ]));
      })
      .catch(() => {
        enqueueSnackbar(
          "We encountered a problem retrieving the access groups",
          { variant: "error", tag: "ErrorRetrievingAccessGroups" }
        );
      });
  }, scopeAwareFacilityID);

  const updateCounter = async (chosenGroups) => {
    let affectedGroups = [];    

    if (chosenGroups.length == 0) {
      setAffectedHolders([]);
      return;
    }

    accessGroups.forEach((group) => {
      chosenGroups.forEach((selection) => {
        if (group.name == selection) {
          affectedGroups.push(group.accessGroupID);
        }
      });
    });

    accessGroupService
      .GetAssignedAccessHoldersByGroups(affectedGroups)
      .then((result) => {
        let uniqueResults = [...new Set(result.data)];
        setAffectedHolders(uniqueResults);
        enqueueSnackbar("Successfully retrieved access holders", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(
          "We encountered a problem retrieving the access holders",
          { variant: "error", tag: "ErrorRetrievingAccessholders" }
        );
      });
  };

  const handleStatusSelect = async (status) => {
    setSelectedStatus(status);
  };

  const handleSelected = async (select) => {
    setSelectedGroups(select.target.value);
    updateCounter(select.target.value);
  };

  const confirmChange = async (accessHolders, affectedGroups) => {
    const confirm = await reasonConfirmation({
      title: "Status Update Confirmation",
      message: `Please confirm you want to change ${accessHolders?.length} access holders I/O status to ${selectedStatus}.`,
      required: false,
    });

    if (!confirm) return;
    try {
      await contractService.syncAccessHolderStatusByGroup({
        AccessGroupIDs: affectedGroups,
        Status: selectedStatus,
        EntityID: scopeAwareFacilityID,
      });  
    } catch {
      enqueueSnackbar(
        `Failed to update status of ${accessHolders?.length} access holders to: ${selectedStatus}`,
        { variant: "error", tag: "FailedToUpdateStatusOfAccessholders" }
      );
      return;
    }
    enqueueSnackbar(
      `Successfully updated status of ${accessHolders?.length} access holders to: ${selectedStatus}`,
      {
        variant: "success",
      }
    );
  };

  const handleSubmit = async () => {
    let affectedGroups = [];

    accessGroups.forEach((group) => {
      selectedGroups.forEach((selection) => {
        if (group.name == selection) {
          affectedGroups.push(group.accessGroupID);
        }
      });
    });

    confirmChange(affectedHolders, affectedGroups);
  };

  return (
    <div>
      <h2 className={clsx(classes.text)}>Status Sync</h2>
      <Dropdown
        className={clsx(
          "access-group-select-dropdown",
          classes.flexDropdown,
          classes.multiSelect
        )}
        classes={{ root: classes.dropdown }}
        data-testid="dropdown-access-group-select"
        name="Access Groups"
        options={accessGroups.map((x) => x.name) ?? []}
        handleChange={(e) => handleSelected(e)}
        withSelectAll={true}
      />
      <SingleSelectDropdown
        className={clsx(
          "status-select-dropdown",
          classes.flexDropdown,
          classes.singleSelect
        )}
        name="Set All To"
        variant="outlined"
        options={availableStatuses}
        onSelect={(e) => handleStatusSelect(e)}
      />
      <Button
        className={clsx("submit-button", classes.button, classes.submitBtn)}
        color="primary"
        variant="contained"
        type="submit"
        onClick={handleSubmit}
      >
        Sync
      </Button>
      <h3
        className={clsx(classes.text)}
      >{`Affected Access Holders: ${affectedHolders.length}`}</h3>
    </div>
  );
};

export default StatusSyncPage;
