import React, { useEffect, useState } from "react";
import useThemeContext from "../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";
import { Button, Divider, Typography } from "@material-ui/core";
import useHubContext from "../../hooks/useHubContext";
import { PORTAL_TRIGGER, DEVICE_STREAM_TOPIC } from "../../constants";
import useCurrentFacility from "../../hooks/useCurrentFacility";

const KioskManagementPage = (props) => {
  const { layoutReducer } = useThemeContext();
  const { facilityID } = useCurrentFacility();
  const [, layoutDispatch] = layoutReducer;
  const { portalHub } = useHubContext();
  const [isGateOpen, setIsGateOpen] = useState(false);

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: "lg" });
  }, [layoutDispatch]);

  useEffect(() => {
    portalHub.subscribe("gate", (message) => {
      setIsGateOpen(!isGateOpen);
    });

    return () => {
      portalHub.unsubscribe("gate");
    };
  }, [isGateOpen, portalHub]);

  const handleOpenGate = () => {
    portalHub.invoke(PORTAL_TRIGGER, {
      entityID: facilityID,
      topic: DEVICE_STREAM_TOPIC,
      method: "gate",
      action: isGateOpen ? "open" : "close",
    });
  };

  return (
    <div>
      <Typography>Kiosk Management!</Typography>
      <Divider/>
            <Button onClick={handleOpenGate}>
          {isGateOpen === false ? "Open" : "Close"} Gate
        </Button>

      <Divider/>
    </div>
  );
};

export default KioskManagementPage;
