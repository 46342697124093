import React, { useEffect } from "react";
import { ListeningProvider } from "../../../hooks/useDeviceListener/useDeviceListenerContext";

import EntityList from "../../../components/Entities/ContextList/ContextList";
import useThemeContext from "../../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../../reducers/layout/layoutReducer";

const DeviceControlPage = props => {
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  return (
    <ListeningProvider>
      <EntityList />
    </ListeningProvider>
  );
};

export default DeviceControlPage;
