import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Grid,
  Typography,
  Divider,
  Card,
  CardActions,
  CardContent,
  Button
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useStyles } from "./index.style";
import { useTheme, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";

import useHasPermissions from "../../../hooks/useHasPermissions";
import apiClient from "../../../auth/apiClient";
import useHubContext from "../../../hooks/useHubContext";
import * as c from "../../../constants/index";

import _ from "lodash";

import useThemeContext from "../../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../../reducers/layout/layoutReducer";


const AdminDashboard = ({ entityID }) => {
  const classes = useStyles();
  const { hasPermissions } = useHasPermissions();
  const viewPermission = hasPermissions(["dashboard.revenue.view"]);
  const isAdmin = useSelector(state => state?.user?.isAdmin);
  const { portalHub, Connected: PortalHubConnected } = useHubContext();

  useEffect(() => {
    return () => {};
  }, []);

  if (!viewPermission || !isAdmin) return <></>;

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h4">Administration</Typography>
      <Divider className={classes.titleDivider}/>

      <Card style={{maxWidth:180, display:"inline-block", marginRight:10}}>
        <CardContent>
          <Typography variant="h6">Connected Users</Typography>
          <div className={classes.cardCountContent}>16</div>
        </CardContent>
        <CardActions className={classes.cardAction}>mock data</CardActions>
      </Card>
      <Card style={{width:200, display:"inline-block"}}>
        <CardContent>
          <Typography variant="h6">Connected Devices</Typography>
          <div className={classes.cardCountContent}>2,503</div>
        </CardContent>
        <CardActions className={classes.cardAction}>mock data</CardActions>
      </Card>

    </div>
  );
};

export default AdminDashboard;
