import React, { useEffect, useState } from "react";
import GroupContractList from "../../components/GroupContracts/GroupContractList";
import { Switch, useHistory } from "react-router-dom";
import CreateEditGroupContract from "../../components/GroupContracts/CreateEditGroupContract";
import ProtectedRoute from "../../components/ProtectedRoute";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import { ContractManagementProvider } from "../../providers/ContractManagementProvider";
import { useViewGroupContract } from "../../hooks/useViewGroupContract";

const GroupContractsPage = () => {
  const history = useHistory();
  const { facilityID } = useCurrentFacility();
  const [contractId, setContractId] = useState();
  const { handleViewGroupContract } = useViewGroupContract();

  const handleAdd = () => {
    setContractId(undefined);
    handleViewGroupContract(undefined);
  };

  const handleChange = (fullcontract) => {
    setContractId(fullcontract.contractID)
    handleViewGroupContract(fullcontract.contractID);
  }
  const handleDelete = () => {
    history.push("/groupcontracts");
  };

  const handleAccessHolderSelected = (id) => {
    history.push("/contracts/accessholders/details", { accessHolderID: id });
  };

  const onSelectGroupContract = (id) => {
    setContractId(id)
    handleViewGroupContract(id);
  }

  useEffect(() => {
    if (history.location.state?.contractId) {
      setContractId(history.location.state.contractId)
    }
    
  }, [history.location.state])

  return (
    <Switch>
      <ContractManagementProvider>
        <ProtectedRoute
          exact
          path="/groupcontracts"
          permissions={["groupcontracts.view"]}
        >
          <GroupContractList
            onAddClick={handleAdd}
            onSelect={(id) => onSelectGroupContract(id)}
          />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path="/groupcontracts/details"
          permissions={["groupcontracts.view"]}
        >
          <CreateEditGroupContract
            contractID={contractId}
            facilityID={facilityID}
            onChange={handleChange}
            onDelete={handleDelete}
            onAccessHolderSelected={handleAccessHolderSelected}
          />
        </ProtectedRoute>
      </ContractManagementProvider>
    </Switch>
  );
};

export default GroupContractsPage;
