import React, { useEffect } from "react";
import { useSelector } from "react-redux";

/* Utility */
import { isUndefined } from "lodash";

/* Hook */
import useThemeContext from "../../hooks/useThemeSwitch";
import useCurrentFacility from "../../hooks/useCurrentFacility";

/* Reducer */
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";

/* Component */
import { Tab, Tabs, Grid } from "@material-ui/core";
import RateSetup from "../../components/Rate/RateSetup";
import TaxForm from "../../components/Forms/Taxes";

/* Style */
import { useStyles } from "./styles";
import clsx from "clsx";
import { faMoneyCheckDollar, faCommentsDollar } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const RateManagement = (props) => {
  const classes = useStyles();  
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const [selectedTabValue, setSelectedTabValue] = React.useState(
    isUndefined(props.location.state?.typeName) ? 
      "tabRates" : 
      props.location.state?.typeName === "taxes" ? 
        "tabTaxes" : ""
    );

  const handleTabChange = (event, value) => {
    setSelectedTabValue(value);
  };

  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Tabs
          centered
          className={clsx(classes.ratesTab)}
          variant="fullWidth"
          value={selectedTabValue}
          onChange={handleTabChange}
        >
          <Tab
            value="tabRates"
            icon={<FontAwesomeIcon icon={faMoneyCheckDollar} size="2xl"/>}
            label="Rates"
            data-testid="rates-tab"
          ></Tab>
          <Tab
            value="tabTaxes"
            icon={<FontAwesomeIcon icon={faCommentsDollar} size="2xl" />}
            label="Taxes"
            data-testid="taxes-tab"
          ></Tab>
        </Tabs>
      </Grid>
      {selectedTabValue === "tabRates" && (
        <Grid container className={classes.tabContent}>
          <Grid
            className={classes.contentContainer}
            item
            xs={12}
          >
            <RateSetup entityID={scopeAwareFacilityID} />
          </Grid>
        </Grid>
      )}
      {selectedTabValue === "tabTaxes" && (
        <Grid container className={classes.tabContent}>
          <Grid
            className={classes.contentContainer}
            item
            xs={8}
          >
            <TaxForm entityID={scopeAwareFacilityID}/>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};