import React, { useEffect } from "react";
import useThemeContext from "../../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../../reducers/layout/layoutReducer";
import { Grid } from "@material-ui/core";
import { useStyles } from "./styles";
import ScheduledReportsProvider from "../../../providers/ScheduledReportsProvider";
import ScheduledReportsList from "../../../components/ScheduledReports/ScheduledReportsList";
import useHasPermissions from "../../../hooks/useHasPermissions";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";

const ScheduledReportsPage = props => {
  const classes = useStyles();
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;
  const { hasPermissions } = useHasPermissions();
  const hasScheduledReportPermission = hasPermissions(["ScheduledReports"]);
  const scheduledReportFeatureFlag = useFeatureFlag("Scheduled Reports");

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  return <>
    <div className={classes.root}>
      {hasScheduledReportPermission && scheduledReportFeatureFlag && (
        <ScheduledReportsProvider>
          <Grid container className={classes.root}>
            <Grid className={classes.contentContainer} item xs={12} lg={12}>
              <ScheduledReportsList
              />
            </Grid>
          </Grid>
        </ScheduledReportsProvider>
      )}
    </div>
</>
};

export default ScheduledReportsPage;

