import React, { useEffect } from "react";
import useThemeContext from "../../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../../reducers/layout/layoutReducer";

const AlertsPage = props => {
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: "lg" });
  }, [layoutDispatch]);

  return <h2>Alerts</h2>;
};

export default AlertsPage;