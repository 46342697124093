import React, { useEffect } from "react";
import useThemeContext from "../../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../../reducers/layout/layoutReducer";
import { Grid } from "@material-ui/core";
import TreeView from "../../../components/TreeView";
import {
  Route,
  Switch
} from "react-router-dom";
import { useStyles } from "./styles";

const SystemPage = (props) => {
  const classes = useStyles();
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  const treeItems = [];

  return (
    <div className={classes.root}>
        <Grid container className={classes.root}>
          <Grid item xs={3} lg={2}>
            <TreeView
              data-id="user-tree-view"
              treeItems={treeItems}
            />
          </Grid>
          <Grid className={classes.contentContainer} item xs={9} lg={10}>
            <Switch>
            </Switch>
          </Grid>
        </Grid>
    </div>
  );
};
export default SystemPage;

