import React, { useEffect, useState } from "react";

/* Components */
import { Typography, MobileStepper } from "@material-ui/core";
import NextButton from "./Navigation/NextButton";
import BackButton from "./Navigation/BackButton";
import Steps from "./Steps";

/* Utilities */
import { useParams, useHistory } from "react-router-dom";
import * as _ from "lodash";
import apiClient from "../../auth/apiClient";
import RateCalculators from "../../components/CashierDevice/Utilities/RateCalculators";

/* State */
import useCurrentUser from "../../hooks/useCurrentUser";
import {
  initializeStyle,
  hydrateTransaction as hydrateTransactionThunk,
  feeCalculated,
} from "../../state/slices/shiftSession/shiftSession";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import { changeFacilities } from "../../state/slices/entities";

/* Style */
import useStyles from "./styles";
import clsx from "clsx";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import useCurrentFacilityTimezone from "../../hooks/useCurrentFacilityTimezone";
import { ResponseTypes } from "../../components/CashierDevice/Utilities/usePos";

const rateCalculators = new RateCalculators(apiClient);

const stepperIndexMap = {
  start: 0,
  selectOffer: 1,
  scanCredential: 1,
  payment: 2,
  scanDiscounts: 2,
  payCash: 3,
  payCredit: 3,
  summary: 4,
};

const CashierDevice = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const { facilityName, facilityID } = useCurrentFacility();
  const shiftSessionsFacilityID = useSelector(
    (state) => state.shiftSession.entityID
  );
  const [loaded, setLoaded] = useState(false);

  const shiftId = useSelector(
    (state) => state.shiftSession.shiftID
  );
  const history = useHistory();
  const { stepName } = useParams();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const toastLocation = small
    ? { vertical: "top", horizontal: "center" }
    : null;
  const hydrationInfo = useSelector((state) => {
    return {
      hydrated: state.shiftSession.transaction.hydrated,
      page: state.shiftSession.transaction.hydratedPage,
      processHydration: state.shiftSession.transaction.processHydration,
    };
  }, shallowEqual);
  const rateCalculationInfo = useSelector((state) => {
    return {
      transactionStarted: state.shiftSession.transaction.started,
      scannedCredential: state.shiftSession.transaction.credential,
      selectedOffer: state.shiftSession.transaction.selectedOffer,
      isAttendedDevice:
        state.shiftSession?.cashieredDeviceID &&
        state.shiftSession?.cashieredDeviceID !== "-1",
      discountsApplied: state.shiftSession.transaction.validations,
      paymentApproved: state.shiftSession.transaction.paymentApproved,
      transactionStatus: state.shiftSession.transaction.status,
      shiftId: state.shiftSession.shiftID,
      endShiftAfterTransaction: state.shiftSession.endShiftAfterTransaction
    };
  }, shallowEqual);
  const { timeZone } = useCurrentFacilityTimezone();
  const enqueueSnackbar = useEnqueueSnackbar();

  useEffect(() => {
    dispatch({
      type: initializeStyle,
      payload: { small: small.toString(), toastLocation },
    });
  }, [small, toastLocation]);

  useEffect(() => {
    if (loaded) {
      if (rateCalculationInfo.shiftId == 0) {
        history.push("/shiftSession/cashieredDevice");
      } else if (!stepName) {
          history.push("/cashieredDevice/start");
      }
    }
  }, [stepName]);

  useEffect(() => {
    if (currentUser.UserID) {
      (async() => {
        await dispatch(hydrateTransactionThunk(currentUser.UserID));
      })()
      if (hydrationInfo.hydrated === false) {
        if (rateCalculationInfo.shiftId == 0) {
          history.push("/shiftSession/cashieredDevice");
        } else if (!stepName) {
            history.push("/cashieredDevice/start");
        }
        setLoaded(true);
      }
    }
  }, [currentUser.UserID]);

  useEffect(() => {
    if (facilityID != shiftSessionsFacilityID) {
      dispatch(
        changeFacilities({
          facilityID: shiftSessionsFacilityID,
          userID: currentUser.UserID,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (hydrationInfo.hydrated) {
      handleRehydrationComplete(hydrationInfo.page);
    } else if (hydrationInfo.hydrated === false)
      enqueueSnackbar("Unable to get transaction info. Please refresh page.", {
        variant: "error",
        anchorOrigin: toastLocation,
        fullwidth: small.toString(),
      });
  }, [hydrationInfo.hydrated, hydrationInfo.page]);

  const handleRehydrationComplete = async (page) => {
    if (!rateCalculationInfo.transactionStarted) {
      goToStep(page);
      setLoaded(true);
      return;
    }
    if (rateCalculationInfo.paymentApproved) {
      goToStep("summary");
      setLoaded(true);
      return;
    }

    let rate = {};
    if (
      rateCalculationInfo.transactionStatus !==
        ResponseTypes.AwaitingPaymentCompleted &&
      rateCalculationInfo.transactionStatus !== ResponseTypes.InProgress &&
      rateCalculationInfo.transactionStatus !== ResponseTypes.Complete
    ) {
      if (rateCalculationInfo.selectedOffer) {
        const res = await rateCalculators.calculatePOERate(
          currentUser.UserID,
          rateCalculationInfo.selectedOffer,
          timeZone,
          rateCalculationInfo.discountsApplied
        );
        rate = res.rate;
      } else {
        rate = await rateCalculators.calculateRateForTicket(
          rateCalculationInfo.scannedCredential,
          currentUser.UserID
        );
      }

      if (!rate) {
        enqueueSnackbar("Unable to recalculate rate", {
          variant: "error",
          tag: "UnableToRecalculateRate",
          anchorOrigin: toastLocation,
          fullwidth: small.toString(),
        });
        setLoaded(true);
        return;
      }

      dispatch({
        type: feeCalculated,
        payload: {
          rateReceipt: rate,
          rateValidUntil: rate.rateValidUntil,
          totalCost: rate.totalToPay,
          initialTotal: rate.baseFee,
        },
      });
    }

    if (rate.totalToPay === 0) goToStep("summary");
    else goToStep(page);
    setLoaded(true);
  };

  const goToStep = (stepRoute) => {

    if (shiftId == 0) {
      history.push(`/shiftSession/cashieredDevice`);
    } else {
      history.push(`/cashieredDevice/${stepRoute}`);
    }
  };

  const goBack = () => {
    const steps = ["scanCredential","selectOffer"]
    if (steps.includes(stepName) && rateCalculationInfo.endShiftAfterTransaction) {      
      history.push(`/shiftSession/cashieredDevice`);
    } else if (stepName !== "summary") {
      history.goBack();
    }
  };

  return (
    <div
      className={clsx(classes.stepsContainer, `${stepName}-step`)}
      data-testid="cashier-v2"
    >
      <div className={classes.stepperHeader}>
        <div className={classes.stepperHeaderInner}>
          <Typography className={"attendant-name"} variant="h6" component="div">
            {currentUser.Username}
          </Typography>
          <Typography className={"device-name"} variant="h6" component="div">
            {facilityName}
          </Typography>
        </div>
      </div>
      {hydrationInfo.hydrated && (
        <Steps classes={classes} onNavigation={goToStep} />
      )}
      {stepName !== "summary" && (
        <MobileStepper
          className={classes.stepper}
          variant="progress"
          steps={5}
          position="static"
          activeStep={stepperIndexMap[stepName]}
          nextButton={<NextButton step={stepName} onClick={goToStep} />}
          backButton={<BackButton step={stepName} onClick={goBack} />}
        />
      )}
    </div>
  );
};

export default CashierDevice;
