import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    margin: theme.spacing(1),
  },
  text: {
    marginLeft: "20px",
  },
  dropdown: {
    minWidth: 150,
  },
  flexDropdown: {
    width: "auto",
    minWidth: "200px",
  },
  singleSelect: {
    marginTop: "15px",
    marginRight: "30px",
  },
  submitBtn: {
    marginTop: "25px",
  },
}));
