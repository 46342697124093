import { Divider, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import JasonTestComponent from "../../components/Test/storeTestBed";
import useThemeContext from "../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";
import useCurrentFacility from "../../hooks/useCurrentFacility";

const KioskMaintenancePage = props => {
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;
  const { facilityID } = useCurrentFacility();  //i know, hacky, just for testing
  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: "lg" });
  }, [layoutDispatch]);

  return (
    <div>
      <Typography>Kiosk Management!</Typography>
      <Divider/>
      <JasonTestComponent entityID={facilityID}></JasonTestComponent>      
    </div>
  );
};

export default KioskMaintenancePage;
