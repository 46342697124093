import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: { margin: "20px" },
  header: {
    display: "flex",
    flex: "1",
  },
  title: {
    width: "100%",
  },
  btnGroup: {
    height: "2rem",
    display: "flex",
    flex: "1",
    justifyContent: "flex-end",
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
  searchBar: {
    marginTop: theme.spacing(-1),
    maxWidth: "100%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "75%",
    },
  },
  accessGroupList: {
    width: "100%",
  },
  filterContainer: {
    display: "inline-flex",
    width: "100%",
    paddingLeft: theme.spacing(2),
  },
}));
