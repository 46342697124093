import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: { 
    padding: theme.spacing(2),
  },
  facilityName: {
    marginTop: 60,
   /* 
    animation:"fadeIn",
    animationDuration:"2s",
    */
    fontWeight:"bold",
    fontSize:"2.6rem",
    color: theme.palette.primary.light,
  }  
}));
