import React, {useReducer} from "react";
import { useSelector } from "react-redux";
import { Drawer } from "@material-ui/core";
import { useFlags } from "launchdarkly-react-client-sdk";
import useHasPermissions from "../../hooks/useHasPermissions";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import { useFeatureFlag } from "../../hooks/useFeatureFlags";
import PayOnEntryProvider from "../../providers/PayOnEntryProvider";
import PayOnEntryContainer from "../../components/PayOnEntry/PayOnEntryContainer";
import PayOnEntryComposer from "../../components/PayOnEntry/PayOnEntryComposer";
import { useStyles } from "./styles";
import clsx from "clsx";

const initalPayOnEntryState = { 
  offerID: undefined, 
  showForm: false 
};
const poeOfferReducer = (state, { type, payload }) => {
  switch (type) {
    case "ADD_POE_OFFER":
      return { ...state, offerID: undefined, showForm: true };
    case "EDIT_POE_OFFER":
      return { ...state, offerID: payload.offerID, showForm: true };
    case "CLOSE_FORM":
      return { ...state, offerID: undefined, showForm: false };
    default:
      return state;
  }
};

export const PayOnEntryPage = () => {
  const classes = useStyles();
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);  
  const payOnEntry = useFeatureFlag("Pay On Entry");
  const { hasPermissions } = useHasPermissions();
  const EditPayOnEntryPermission = hasPermissions(["payonentry.edit"]);
  const AddPayOnEntryPermission = hasPermissions(["payonentry.add"]);
  const ViewPayOnEntryPermission = hasPermissions(["payonentry.view"]);

  const [state, dispatch] = useReducer(
    poeOfferReducer,
    initalPayOnEntryState
  );

  const handleAdd = () => {
    dispatch({ 
      type: "ADD_POE_OFFER" 
    });
  };

  const handleEdit = offerID => {
    dispatch({
      type: "EDIT_POE_OFFER", 
      payload: {
        offerID }
      });
  };

  const handleCancel = () => {
    dispatch({ 
      type: "CLOSE_FORM" 
    });
  };

  const handleSaved = () => {
    dispatch({ 
      type: "CLOSE_FORM" 
    });
  };

  return (
    <>
      {payOnEntry && (
        <PayOnEntryProvider>
          {ViewPayOnEntryPermission ? (
          <PayOnEntryContainer
            onAddClick={handleAdd}
            onEditClick={handleEdit}
            entityId={scopeAwareFacilityID}
          />
          ) : (React.Fragment)}
          {(AddPayOnEntryPermission || EditPayOnEntryPermission ) && (
          <Drawer
            className={clsx("pay-on-entry", "drawer")}
            anchor="right" 
            open={state.showForm}
            classes={{ paper: classes.drawerPaper, root: classes.drawer}}
          >
          <PayOnEntryComposer
              entityID={scopeAwareFacilityID}
              offerId={state.offerID}
              handleSave={handleSaved}
              handleCancel={handleCancel}
          />
          </Drawer>
          )}
        </PayOnEntryProvider>
      )}
    </>
  );
};

