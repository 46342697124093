import React, { useEffect, useCallback, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { isUndefined } from "lodash";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import useAuthContext from "../../hooks/useAuthContext";
import UserService from "../../services/UserService";
import apiClient from "../../auth/apiClient";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import {
  LinearProgress,
  Typography,
  Grid,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./styles";
import ColoredLine from "../../components/ColoredLine";
import ContractService from "../../services/ContractService";
import GroupContractService from "../../services/GroupContractService";
import CreditCardOnFileService from "../../services/CreditCardOnFileService";
import { CARD_TRANSACTION_STATE } from "../../constants/index"

const userService = new UserService(apiClient);
const contractService = new ContractService(apiClient);
const groupContractService = new GroupContractService(apiClient);
const creditCardOnFileService = new CreditCardOnFileService(apiClient);

const CardOnFilePage = ({}) => {
  const classes = useStyles();
  const { returnPath, contractID, accessHolderID } = useParams();
  const location = useLocation();
  const history = useHistory();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const { authReducer } = useAuthContext();
  const [authState] = authReducer;
  const currentUserID = authState.userSession?.idToken?.payload?.userid;
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [cardOnFileMessage, setCardOnFileMessage] = useState("");

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    setLoading(true);
    async function work() {
      await getPermissions(scopeAwareFacilityID, currentUserID);
    }
    work();
  }, [scopeAwareFacilityID, currentUserID]);

  const getPermissions = useCallback(
    async (entityID, userID) => {
      let response;
      try {
        response = await userService.getScopedPermissionsWithEntity(
          userID,
          entityID
        );
      } catch {
        enqueueSnackbar("Failed to retrieve permissions", {
          variant: "error",
          tag: "FailedToRetrievePermissions",
        });
        setRedirect(false);
        setLoading(false);
        return;
      }

      try {
        let scoped = response.data?.find((x) => x.entityID === facilityID);
        if (scoped && scoped.permissions) {
          let permList = scoped.permissions;
          let scope = null;
          if (isUndefined(accessHolderID)) {
            // AccessHolderID is not defined so we are working with a group contract.
            // See if we can find the group contract view permission.
            scope = permList.find(
              (x) => x.permissionName == "groupcontracts.view"
            );
            let groupContract = await groupContractService.getGroupContract(
              entityID,
              contractID
            );
            if (
              groupContract?.data.contractID == contractID &&
              !isUndefined(scope) &&
              returnPath === "groupcontract"
            ) {
              setRedirect(true);
            } else {
              setRedirect(false);
            }
            setLoading(false);
          } else {
            // AccessHolderID is defined so we are working with an Access holder contract.
            // See if we can find the access holder view permission
            scope = permList.find(
              (x) => x.permissionName == "accessholders.view"
            );
            let response = await contractService.getAccessHolder(
              entityID,
              accessHolderID
            );
            let tmpAccessHolder = response.data;

            if (
              contractID == tmpAccessHolder.contractID &&
              !isUndefined(scope) &&
              returnPath === "accessholder"
            ) {
              setRedirect(true);
            } else {
              setRedirect(false);
            }
            setLoading(false);
          }
        } else {
          setRedirect(false);
          setLoading(false);
        }
      } catch (error) {
        setRedirect(false);
        setLoading(false);
        console.error("Error getting permissions");
      }
    },
    [scopeAwareFacilityID, currentUserID]
  );

  const pushReturnPathHistory = useCallback(async () => {
    if (isUndefined(accessHolderID) && redirect == true) {
      history.push("/groupcontracts/details", {
        contractId: contractID,
        cardOnFileMessage: cardOnFileMessage,
      });
    } else if (!isUndefined(accessHolderID) && redirect == true) {
      history.push("/contracts/accessholders/details", {
        accessHolderID: accessHolderID,
        cardOnFileMessage: cardOnFileMessage,
      });
    }
  }, [redirect, accessHolderID, history, cardOnFileMessage]);

  useEffect(() => {
    if (cardOnFileMessage) {
      pushReturnPathHistory();
    }
  }, [redirect, cardOnFileMessage, pushReturnPathHistory]);

  useEffect(() => {
    try {
      let queryParams = new URLSearchParams(location.search);
      if (queryParams.has("mess")) {
        const errM = queryParams.get("mess");
        setCardOnFileMessage(errM);
        queryParams.delete("mess");
      }
      if (queryParams.has("sessionId")) {
        queryParams.delete("sessionId");
      }
      history.replace({ search: queryParams.toString() });
      return;
    } catch (error) {
      console.error("Error getting query strings");
    }
  }, []);

  const setTransactionStatus = useCallback(async (cardOnFileMessage) => {
    let contractTransactionState;

    try {
      let queryParams = new URLSearchParams(location.search);
      if (queryParams.has("message")) {
        setCardOnFileMessage(queryParams.get("message"));
      }
    } catch (error) {
      console.error("Error getting query strings");
    }

    if (cardOnFileMessage) {
      
      if (cardOnFileMessage !== "cancel" && cardOnFileMessage !== "fail") {
        try {
          var response = await creditCardOnFileService.getTransactionState(contractID);
          contractTransactionState = response.data;
          setCardOnFileMessage(getTransactionState(contractTransactionState));
        } catch (error) {
          console.error("Error getting contract transaction state");
        }
      }

      try {
        // message=cancel would be triggered when user clicks cancel on payment hosting page.
        // message=fail would be triggered for windcave if a bad card was entered on their page.
        // For either case, need to delete transaction state because action will not hit backend token processing api.
        if (cardOnFileMessage === "cancel" || cardOnFileMessage === "fail")
          await creditCardOnFileService.deleteTransactionState(contractID);
      } catch (error) {
        console.error("Error deleting contract transaction state");
      }
    }

  }, [contractID, cardOnFileMessage]);

  function getTransactionState(contractTransactionState) {
    let cardOnFileMessage;
    switch (contractTransactionState) {
      case CARD_TRANSACTION_STATE.PENDING:
        cardOnFileMessage = "pending";
        break;
      case CARD_TRANSACTION_STATE.SUCCESS:
        cardOnFileMessage = "success";
        break;
      case CARD_TRANSACTION_STATE.FAIL:
      default:
        cardOnFileMessage = "fail";
        break;
    }
    return cardOnFileMessage;
  }

  useEffect(() => {
    setTransactionStatus(cardOnFileMessage);
  }, [cardOnFileMessage]);

  return (
    <div>
      {loading ? (
        <LinearProgress style={{ width: "100%", opacity: 0.5 }} data-testid="loading-progress" />
      ) : !redirect ? (
        <Dialog
          className={classes.editDialog}
          maxWidth="xs"
          fullWidth
          open={modalOpen}
          onClose={toggleModal}
          data-testid="card-on-file-access-denied"
        >
          <DialogContent className="credit-card-dialog">
            <Typography
              component="h2"
              variant="h4"
              color="primary"
              align="center"
              gutterBottom={true}
            >
              You can not view this{" "}
              {isUndefined(accessHolderID)
                ? "group contract"
                : "access holder contract"}
            </Typography>
            <ColoredLine />
            <Typography
              component="h5"
              variant="h6"
              color="primary"
              className={classes.insufficientPermissions}
              align="center"
              gutterBottom={true}
            >
              {!redirect ? "Insufficient permissions" : "unknown"}
            </Typography>
          </DialogContent>
        </Dialog>
      ) : null}
    </div>
  );
};

export default CardOnFilePage;
