import React from "react";
import PropTypes from "prop-types";

/* Components */
import { Button } from "@material-ui/core";

/* Style */
import useStyles from "./styles";

/* State */
import { useSelector } from "react-redux";

const BackButton = ({ step, onClick }) => {
  const classes = useStyles();
  const processingCash = useSelector((state) => state.shiftSession.transaction.processingCash);
  const validationShowBack = useSelector((state) => state.shiftSession.validationNavigation?.enableBack);

  return (
    <>
      {(() => {
        switch (step) {
          case "start":
          case "payCredit":
          case "payment":
          case "valetArrival":
          case "valetRequest":
            return <div className={classes.backButtonPlaceholder} />;
          case "payCash":
            return (
              <Button
                className={classes.backButton}
                variant="contained"
                color="primary"
                size="small"
                data-testid="backButton"
                onClick={onClick}
                disabled={processingCash}
              >
                Back
              </Button>
            );    
            case "scanDiscounts":
            return (
              <Button
                className={classes.backButton}
                variant="contained"
                color="primary"
                size="small"
                data-testid="backButton"
                onClick={onClick}
                disabled={validationShowBack === false}
              >
                Back
              </Button>
            );
          default:
            return (
              <Button
                className={classes.backButton}
                variant="contained"
                color="primary"
                size="small"
                data-testid="backButton"
                onClick={onClick}
              >
                Back
              </Button>
            );
        }
      })()}
    </>
  );
};

BackButton.defaultProps = {
  onClick: () => {},
};

BackButton.propTypes = {
  step: PropTypes.string,
  onClick: PropTypes.func,
};

export default BackButton;
