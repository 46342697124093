import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  drawer :{
  },
  drawerPaper: {
      [theme.breakpoints.up('sm')]: {
        width: "40%",
        flexShrink: 0,
      },
      [theme.breakpoints.up('lg')]: {
        width: "33%",
        flexShrink: 0,
      },
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        flexShrink: 0,
      },    
  },
}));
