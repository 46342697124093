import React from "react";
import EventsPageContainer from "./EventsPageContainer";
import FormStateProvider from "../../providers/FormStateProvider";

const EventsPage = () => {
  return (
    <FormStateProvider>
      <EventsPageContainer />
    </FormStateProvider>
  );
};

export default EventsPage;
