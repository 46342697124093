import React from "react";
import { Tab, Tabs } from "@material-ui/core";
import useHasPermissions from "../../hooks/useHasPermissions";
import CashierProvider from "../../providers/CashierProvider";
import RoamingCCTerminals from "../../components/RoamingCCTerminals";
import CashierSessions from "../../components/CashierSessions";
import { useStyles } from "./styles";
import clsx from "clsx";
import { faUserFriends, faCreditCard } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFlags } from "launchdarkly-react-client-sdk";

export const CashierPage = () => {
  const { hasPermissions } = useHasPermissions();
  const CreditCardViewPermission = hasPermissions(["roamingcreditcardterminalsetup.view"]);
  const CashierSessionsViewPermission = hasPermissions(["cashiersessions.view"]);
  const [selectedTabValue, setSelectedTabValue] = React.useState(CashierSessionsViewPermission ? "tabCashierSessions" : CreditCardViewPermission ? "tabCreditCardTerminals" : "" );
  const classes = useStyles();  
  const { cashieredDevices } = useFlags();

  const handleTabChange = (event, value) => {
    setSelectedTabValue(value);
  };

  function TabPanel(props) {
    const { children, value } = props;
    return (
      <div className={clsx(classes.tabPanel)}>
        {selectedTabValue == value && children}
      </div>
    );
  }
  return (
    <>
      {cashieredDevices &&
        <>
          <Tabs
            centered
            className={clsx(classes.cashierTab)}
            variant="fullWidth"
            value={selectedTabValue}
            onChange={handleTabChange}
          >
            {CashierSessionsViewPermission && (
              <Tab
                value="tabCashierSessions"
                icon={<FontAwesomeIcon icon={faUserFriends} size="2xl"/>}
                label="Cashier Sessions"
                data-testid="cashier-sessions-tab"
              ></Tab>
            )}
            {CreditCardViewPermission && (
              <Tab
                value="tabCreditCardTerminals"
                icon={<FontAwesomeIcon icon={faCreditCard} size="2xl" />}
                label="Credit Card Terminals"
                data-testid="credit-card-terminals-tab"
              ></Tab>
            )}
            </Tabs>
            <TabPanel value="tabCashierSessions" index={0}>
              {CashierSessionsViewPermission && (
                <CashierSessions />
              )}                
            </TabPanel>
            <TabPanel value="tabCreditCardTerminals" index={1}>
              {CreditCardViewPermission && (
                <CashierProvider>
                  <RoamingCCTerminals/>
                </CashierProvider>
              )}        
            </TabPanel>
          </>
        }
      </>
  );
};