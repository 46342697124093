import React, { useEffect } from "react";
import useThemeContext from "../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";

import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./styles";


const AccountManagementPage = () => {
  const classes = useStyles();
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;



  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  return (
    <Grid container className={classes.root}>
      <Typography variant="h1">ACCOUNT MANAGEMENT</Typography>
    </Grid>
  );
};

export default AccountManagementPage;
