import React, { useEffect } from "react";
import { ContractManagementProvider } from "../../providers/ContractManagementProvider";
import { useStyles } from "./style";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";
import useThemeContext from "../../hooks/useThemeSwitch";
import { Switch } from "react-router-dom";
import { Grid } from "@material-ui/core";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import PeopleIcon from "@material-ui/icons/People";
import AccessHolderPage from "../AccessHolderPage";
import ProtectedRoute from "../../components/ProtectedRoute";

const ContractManagement = () => {
  const classes = useStyles();
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  const treeItems = [
    {
      name: "Contracts",
      icon: DriveEtaIcon,
      link: `/contracts`,
    },
    {
      name: "Contract Holders",
      icon: PeopleIcon,
      link: `/contracts/contractholders`,
    },
    {
      name: "Access Holders",
      icon: LocalParkingIcon,
      link: `/contracts/accessholders`,
    },
  ];

  return (
    <div className={classes.root}>
      <ContractManagementProvider>
        <Grid container className={classes.root}>
          <Grid className={classes.contentContainer} item xs={12} lg={10}>
            <Switch>
              <ProtectedRoute
                path="/contracts/accessholders"
                component={AccessHolderPage}
                permissions={["accessholders.view"]}
              />
            </Switch>
          </Grid>
        </Grid>
      </ContractManagementProvider>
    </div>
  );
};

export default ContractManagement;
