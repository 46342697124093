import React from "react";
import { useSelector } from "react-redux";
import Title from "../../../components/Title";
import Labels from "../Labels";
import TextEditor from "../TextEditor";
import DateEditor from "../DateEditor";
import useFormState from "../../../hooks/useFormState";
import Loading from "../../../components/Loading";
import useHasPermissions from "../../../hooks/useHasPermissions";
import {
  formatForUpdate,
  validate,
  createCalendarData,
  formatForValidate
} from "../utilities";
import {
  Box,
  IconButton,
  Typography,
  Drawer,
  Card,
  Paper
} from "@material-ui/core";
import { useStyles } from "./styles";
import moment from "moment";
import ConfirmDialog from "../../../components/ConfirmDialog";
import RateService from "../../../services/RateService";
import TodayIcon from "@material-ui/icons/Today";
import apiClient from "../../../auth/apiClient";
import CommandButton from "../CommandButton";
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import Alert from "../../../components/Alert";
import {
  Scheduler,
  Appointments,
  AppointmentForm,
  AppointmentTooltip,
  EditRecurrenceMenu,
  MonthView,
  AllDayPanel,
  ConfirmationDialog
} from "@devexpress/dx-react-scheduler-material-ui";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { DatePicker } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import useCurrentFacility from "../../../hooks/useCurrentFacility";

const service = new RateService(apiClient);

const generateAlert = (type, message) => {
  switch (type) {
    case "validation":
      return { type: "error", open: true, message: message };
    case "newEventCreated":
      return {
        type: "success",
        open: true,
        message: `Created ${message} Event`
      };
    case "eventCreationFailure":
      return {
        type: "error",
        open: true,
        message: "There Was An Error Creating The Event"
      };
    case "eventDeletedSuccess":
      return { type: "success", open: true, message: "Event Deleted" };
    case "eventDeleteFailure":
      return {
        type: "error",
        open: true,
        message: "We Ran In To An Issue Deleting The Event"
      };
    case "nameChangeFailure":
      return {
        type: "error",
        open: true,
        message: "Event Name Cannot Be Changed"
      };
    case "updateSuccess":
      return { type: "success", open: true, message: `Updated ${message}` };
    case "updateFailure":
      return {
        type: "error",
        open: true,
        message: `Failed To Update ${message}`
      };
    case "getEventsFailure":
      return {
        type: "error",
        open: true,
        message: "There Was An Error Locating Your Events"
      };
    default:
      return null;
  }
};

const EventsPageContainer = () => {
  const {
    isEditingStarted,
    setIsEditingStarted,
    clearErrors,
    isEventStarted,
    isDateInPast
  } = useFormState();
  const { hasPermissions } = useHasPermissions();
  const lacksPermission = !hasPermissions(["settings.rates.write"]);
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const classes = useStyles();
  const [calendarData, setCalendarData] = React.useState([]);
  const [viewDate, setViewDate] = React.useState(moment());
  const [allRateApplications, setAllRateApplications] = React.useState([]);
  const [pickerOpen, setPickerOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleViewForm = values => {
    values.title
      ? isEventStarted(values.startDate)
      : isDateInPast(values.startDate);
  };

  const handleChange = ({ added, changed, deleted }) => {
    if (added) {
      handleAdd(added);
    } else if (changed) {
      const changes = Object.entries(changed);
      const formattedChanges = { ...changes[0][1], id: changes[0][0] };
      handleUpdate(formattedChanges);
    } else if (deleted !== undefined) {
      handleDelete(deleted);
    }
  };

  const handleAdd = values => {
    const error = validate(values);
    if (error) {
      setAlert(generateAlert("validation", error));
      return;
    }
    let allApplications = allRateApplications;
    service
      .createOverlayRateApplication(scopeAwareFacilityID, values)
      .then(result => {
        const newApplication = result.data;
        newApplication.map(item => allApplications.push(item));
        setAllRateApplications(allApplications);
        setAlert(generateAlert("newEventCreated", values.title));
      })
      .catch(() => {
        setAlert(generateAlert("eventCreationFailure"));
      })
      .finally(() => {
        const filtered = allApplications.filter(
          item => item.overlaidRate === true
        );
        setCalendarData(createCalendarData(filtered));
      });
  };

  const handleDelete = deleted => {
    let updatedData = calendarData;
    service
      .deleteOverlayApplication(scopeAwareFacilityID, deleted)
      .then(() => {
        setAlert(generateAlert("eventDeletedSuccess"));
        const index = allRateApplications.findIndex(
          x => x.entityRateID === deleted
        );
        const updatedList = allRateApplications;
        updatedList.splice(index, 1);
        setAllRateApplications(updatedList);
        updatedData = calendarData.filter(item => item.id !== deleted);
      })
      .catch(() => {
        setAlert(generateAlert("eventDeleteFailure"));
      })
      .finally(() => {
        setCalendarData(updatedData);
      });
  };
  const handleUpdate = changed => {
    let updatedData = calendarData;
    const index = allRateApplications.findIndex(
      x => x.entityRateID === changed.id
    );
    const original = allRateApplications[index];
    if (changed.title) {
      setAlert(generateAlert("nameChangeFailure"));
      return;
    }
    const updateRequest = formatForUpdate(changed, original);
    const error = validate(formatForValidate(updateRequest));
    if (error) {
      setAlert(generateAlert("validation", error));
      return;
    }
    service
      .updateOverlayApplications(scopeAwareFacilityID, updateRequest)
      .then(result => {
        updatedData = calendarData.filter(item => item.id !== changed.id);
        const formatted = createCalendarData([result.data]);
        formatted.map(item => updatedData.push(item));
        const updatedList = allRateApplications;
        updatedList[index] = result.data;
        setAllRateApplications(updatedList);
        setAlert(generateAlert("updateSuccess", result.data.rateTitle));
      })
      .catch(() => {
        setAlert(generateAlert("updateFailure", original.rateTitle));
      })
      .finally(() => {
        setCalendarData(updatedData);
      });
  };

  const handleStartDateChange = value => {
    setViewDate(value);
    setPickerOpen(false);
  };

  React.useEffect(() => {
    setIsLoading(true);
    service
      .getOverlayRates(
        scopeAwareFacilityID,
        moment(viewDate)
          .add(-1, "months")
          .toDate(),
        moment(viewDate)
          .add(1, "months")
          .toDate()
      )
      .then(result => {
        setAllRateApplications(result.data);
        const overlaidRates = result.data?.filter(
          item => item.overlaidRate === true
        );
        setCalendarData(createCalendarData(overlaidRates));
      })
      .catch(() => {
        setAlert(generateAlert("getEventsFailure"));
      })
      .finally(() => {
        setIsLoading(false);
      }, []);
  }, [scopeAwareFacilityID, viewDate]);
  
  return (
    <Box>
      <Box className={classes.title}>
        <Title>Special Event Rates</Title>
      </Box>
      {isLoading && <Loading />}
      {!isLoading && (
        <Paper>
          <Grid container className={classes.mediumMargin}>
            <Grid item xs={6} sm={4}>
              <IconButton
                onClick={() => setViewDate(moment(viewDate).add(-1, "month"))}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <IconButton
                onClick={() => setViewDate(moment(viewDate).add(1, "month"))}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
              <IconButton onClick={() => setPickerOpen(true)}>
                <TodayIcon />
              </IconButton>
              {pickerOpen && (
                <DatePicker
                  open={pickerOpen}
                  onClose={() => setPickerOpen(false)}
                  autoOk
                  onChange={value => handleStartDateChange(value)}
                />
              )}
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography className={classes.monthName}>
                {moment(viewDate).format("MMMM")}
              </Typography>
            </Grid>
            <Grid item sm={4} />
          </Grid>
          <Card>
            <Scheduler data={calendarData}>
              <ViewState
                currentDate={viewDate}
                onCurrentDateChange={value => {
                  setViewDate(value);
                }}
              />
              <EditingState
                onCommitChanges={async value => await handleChange(value)}
              />
              <MonthView />
              <AllDayPanel />
              <EditRecurrenceMenu />

              {isEditingStarted && (
                <ConfirmationDialog
                  layoutComponent={ConfirmDialog}
                  overlayComponent={DialogOverlay}
                />
              )}
              <Appointments />
              <AppointmentTooltip
                showOpenButton
                onVisibilityChange={() => setIsEditingStarted(true)}
                onAppointmentMetaChange={values => {
                  handleViewForm(values.data);
                }}
                commandButtonComponent={CommandButton}
                showDeleteButton={!lacksPermission}
              />
              <AppointmentForm
                onAppointmentDataChange={values => {
                  handleViewForm(values);
                }}
                onVisibilityChange={clearErrors}
                overlayComponent={EditingDrawer}
                textEditorComponent={TextEditor}
                labelComponent={Labels}
                commandButtonComponent={CommandButton}
                dateEditorComponent={DateEditor}
                booleanEditorComponent={BooleanSelector}
              />
            </Scheduler>
          </Card>
        </Paper>
      )}

      {alert && (
        <Alert
          open={alert.open}
          onClose={() => setAlert(null)}
          type={alert.type}
        >
          {alert.message}
        </Alert>
      )}
    </Box>
  );
};

const EditingDrawer = props => {
  return (
    <>
      {props.visible && (
        <Drawer anchor="right" open={true}>
          {props.children}
        </Drawer>
      )}
    </>
  );
};

const DialogOverlay = props => {
  const classes = useStyles();
  return (
    <>
      {props.visible && <Box className={classes.overlay}>{props.children}</Box>}
    </>
  );
};

const BooleanSelector = props => {
  return <></>;
};

export default EventsPageContainer;
