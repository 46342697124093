import React from "react";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";
import { Grid, Typography } from "@material-ui/core";
import FacilityActivity from "../../../components/Administration/FacilityActivity";

const FacilityActivityPage = () => {
  const classes = useStyles();
  const isAdmin = useSelector(state => state?.user?.isAdmin);

  if (!isAdmin) return <></>;

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h4">Facility Activity</Typography>
      <Grid className={classes.contentContainer} item xs={12} md={12} lg={12}>
        <FacilityActivity></FacilityActivity>
      </Grid>
    </div>
  );
};

export default FacilityActivityPage;