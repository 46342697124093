import React from "react";
import SettingsContainer from "../../components/Settings/SettingsContainer";
import useThemeContext from "../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";

const SettingsPage = () => {
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;

  React.useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  return <SettingsContainer />;
};

export default SettingsPage;
