import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "./styles";
import CloseIcon from "@material-ui/icons/Close";
import useHasPermissions from "../../../hooks/useHasPermissions";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import useFormState from "../../../hooks/useFormState";
import isEmpty from "lodash/isEmpty";

const CommandButton = props => {
  const { formErrors, isEditingStarted } = useFormState();
  const { hasPermissions } = useHasPermissions();
  const lacksPermission = !hasPermissions(["settings.rates.write"]);
  const classes = useStyles();
  const { id, onExecute } = props;
  const errorList = Object.values(formErrors).filter(error => error !== "");
  return (
    <>
      {id === "cancelButton" && (
        <IconButton
          className={classes.left}
          variant="contained"
          onClick={onExecute}
        >
          <CloseIcon />
        </IconButton>
      )}
      {id === "saveButton" && !lacksPermission && (
        <Button
          disabled={!isEmpty(errorList) || !isEditingStarted}
          variant="contained"
          color="primary"
          onClick={lacksPermission ? () => {} : onExecute}
        >
          Save
        </Button>
      )}

      {id === "delete" && !lacksPermission && (
        <IconButton onClick={onExecute}>
          <DeleteIcon />
        </IconButton>
      )}
      {id === "open" && (
        <IconButton className={classes.left} onClick={onExecute}>
          <ExpandLessIcon />
        </IconButton>
      )}
    </>
  );
};
export default CommandButton;
