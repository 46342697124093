import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  mediumMargin: {
    margin: theme.spacing(2)
  },
  monthName: {
    fontSize: 30,
    textAlign: "center"
  },
  overlay: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 10000,
    backgroundColor: "rgba(50, 50, 50, .3)"
  }
}));
