import React from "react";
import { useStyles } from "./styles";
import { Grid , Typography} from "@material-ui/core";
import Title from "../../components/Title";
import clsx from "clsx";
import Zoom from '@material-ui/core/Zoom';

import { useSelector, useDispatch } from "react-redux";

const Home = () => {
  const classes = useStyles();
  const facilityName  = useSelector((state) => state.entities.Context.name);

  return (
    <div className={classes.root}>
      <Grid container 
        spacing={0} 
        direction="column" 
        alignItems="center" 
        justify="center"
        style={{minHeight:'55vh'}}>
          <img 
            style={{marginTop:30}}
            width="40%"
            className={clsx(["animate__animated","animate__flip"])} 
            src="/images/logos/a1-logo-stacked-dark.svg"
          />
          <Zoom in={true} style={{ transitionDelay: '700ms'}}>
            <Typography className={clsx(classes.facilityName, "facility-name")}>{facilityName}</Typography>
          </Zoom>          
          
      </Grid>
    </div>
  );
};

export default Home;
