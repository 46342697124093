import React from "react";
import { useStyles } from "./styles";
import Typography from "@material-ui/core/Typography";

const Labels = props => {
  const classes = useStyles();
  const labels = {
    TITLE: { fieldHeader: true, text: "Title" },
    RATE: { fieldHeader: true, text: "Rate" },
    REPEAT: { fieldHeader: true, text: "Repeat" },
    END_REPEAT: { fieldHeader: true, text: "End Repeat" },
    SPACER: { text: " " },
    ON: { text: "On" },
    NEVER: { text: "Never" },
    AFTER: { text: "After" },
    REPEAT_EVERY: { text: "Repeat Every" },
    DAYS: { text: "day(s)" },
    DEFAULT: { text: "" }
  };

  const findLabel = name => {
    switch (name) {
      case "Details":
        return labels.TITLE;
      case "More Information":
        return labels.RATE;
      case "-":
        return labels.SPACER;
      case "Repeat":
        return labels.REPEAT;
      case "On":
        return labels.ON;
      case "Never":
        return labels.NEVER;
      case "After":
        return labels.AFTER;
      case "Repeat Every":
        return labels.REPEAT_EVERY;
      case "day(s)":
        return labels.DAYS;
      default:
        return "";
    }
  };

  const label = findLabel(props.text);
  return (
    <Typography
      variant={label.size}
      component={label.fieldHeader && "h4"}
      className={`${label === labels.SPACER &&
        classes.spacer} ${label.fieldHeader && classes.fieldHeader}
      ${label === labels.RATE && classes.rateHeader}`}
    >
      {label.text}
    </Typography>
  );
};

export default Labels;
