import React, { useEffect } from "react";
import useThemeContext from "../../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../../reducers/layout/layoutReducer";
import { Grid } from "@material-ui/core";
import { useStyles } from "./styles";
import IntegratorSetup from "../../../components/Integrators/IntegratorSetup"
import useCurrentFacility from "../../../hooks/useCurrentFacility";

const IntegratorSetupPage = (props) => {
  const classes = useStyles();
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;
  const { facilityID } = useCurrentFacility();

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: "xl" });
  }, [layoutDispatch]);

  return (
    <>
        <Grid container className={classes.root}>
          <Grid className={classes.contentContainer} item xs={12} md={12} lg={12}>
            <IntegratorSetup entityID={facilityID} />
          </Grid>
        </Grid>
        </>
  );
};
export default IntegratorSetupPage;

