import React, { useState, useEffect, useLayoutEffect } from "react";
import { useStyles } from "./styles";
import clsx from "clsx";
import { useParams, useLocation, useHistory } from "react-router-dom";
import AuthService from "../../services/AuthService";
import apiClient, { setAuthentication as setApiAuth } from "../../auth/apiClient";
import { setAuthenticationState } from "../../reducers/auth/authReducer";
import MobilePayment from "../../components/MobilePayment";
import ScanAgain from "../../components/MobilePayment/ScanAgain";
import GenericError from "../../components/MobilePayment/GenericError";
import useAuthContext from "../../hooks/useAuthContext";
import { Grid, CircularProgress } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

const authService = new AuthService(apiClient);

const MobilePayPage = ({ scanAgain }) => {
  const classes = useStyles();
  const { ticketId } = useParams();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [result, setResult] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { authReducer } = useAuthContext();
  const [, authDispatch] = authReducer;
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  const getAuth = async () => {
    try {
      var appClientToken = await authService.getMobilePayAuthToken();
      if (appClientToken !== "") {
        setApiAuth(appClientToken);
        authDispatch({
          type: setAuthenticationState,
          payload: { properties: [] },
        });
        setIsAuthenticated(true);
      }
    } catch (error) {
      console.error("Error Authenticating", error);
    } finally {
      setIsLoading(false);
    }
  };

  const parseQueryParams = () => {
    try {
      const queryParams = new URLSearchParams(location.search);
      // NOTE: this param "result" will be deprecated by "sessionId" by the new Rest API
      if (queryParams.has("result")) {
        setResult(queryParams.get("result"));
      }
    } catch (error) {
      console.error("Error getting result", error);
    }
  };

  const deleteQueryParams = (names) => {
    try {
      const queryParams = new URLSearchParams(location.search);
      let hasDeleted = false;
      names.forEach((name) => {
        if (queryParams.has(name)) {
          queryParams.delete(name);
          hasDeleted = true;
        }
      });
      if (hasDeleted) {
        history.replace({
          search: queryParams.toString(),
        });
      }
    } catch (error) {
      console.error("Error delete param", error);
    }
  };

  useEffect(() => {
    if (scanAgain === false && !isAuthenticated) {
      parseQueryParams();
      getAuth();
    }
  }, [authDispatch, isAuthenticated]);

  useEffect(() => {
    if (scanAgain === true) {
      deleteQueryParams(["result", "userid", "sessionId"]);
    }
  }, [scanAgain, location]);

  useLayoutEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.level1;
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img className={classes.logo} alt="company logo" src="/images/logos/a1-logo-stacked-dark.svg" />
        </Grid>
      </Grid>
      <div className={clsx(classes.contentContainer, "mobile-payment-content")}>
        {scanAgain === true ? (
          <ScanAgain></ScanAgain>
        ) : (
          <>
            {isLoading === true ? (
              <CircularProgress size={60} />
            ) : (
              <>
                {ticketId && isAuthenticated ? (
                  <div>
                    <MobilePayment
                      ticketId={ticketId}
                      result={result}
                      deleteQueryParam={() => {
                        deleteQueryParams(["result", "userid"]);
                      }}
                    ></MobilePayment>
                  </div>
                ) : (
                  <GenericError message="Please try re-scanning with phone"></GenericError>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MobilePayPage;
