import React, { useEffect } from "react";
import { ListeningProvider } from "../../hooks/useDeviceListener/useDeviceListenerContext";

import CallCenter from "../../components/CallCenter";
import useThemeContext from "../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";

const CallCenterPage = () => {
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  return (
    <ListeningProvider>
      <CallCenter />
    </ListeningProvider>
  );
};

export default CallCenterPage;
