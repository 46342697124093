import React from "react";
import { Switch } from "react-router-dom";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import RoutineServiceContainer from "../../components/RoutineService/RoutineServiceContainer";
import RoutineComposer from "../../components/RoutineService/RoutineComposer";
import RoutineHistory from "../../components/RoutineService/RoutineHistory";
import RoutineProvider from "../../providers/RoutineProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import ProtectedRoute from "../../components/ProtectedRoute";
import useHasPermissions from "../../hooks/useHasPermissions";

const initalRoutineState = { routineID: undefined, showForm: false };
const routineReducer = (state, { type, payload }) => {
  switch (type) {
    case "ADD_ROUTINE":
      return { ...state, routineID: undefined, showForm: true };
    case "EDIT_ROUTINE":
      return { ...state, routineID: payload.routineID, showForm: true };
    case "CLOSE_FORM":
      return { ...state, routineID: undefined, showForm: false };
    default:
      return state;
  }
};

export const RoutineServiceRouter = () => {
  const { routinesMainMenu } = useFlags();

  return (
    <>
      {routinesMainMenu && (
        <Switch>
          <RoutineProvider>
            <ProtectedRoute
              exact
              path="/routineservice"
              permissions={["routines.view"]}
              data-id="routine-service-container"
            >
              <RoutineServicePageContent />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path="/routineservice/history"
              permissions={["routines.view"]}
              component={() => {
                return <RoutineHistory />;
              }}
            />
          </RoutineProvider>
        </Switch>
      )}
    </>
  );
};

export const RoutineServicePageContent = () => {
  const { facilityID } = useCurrentFacility();
  const { hasPermissions } = useHasPermissions();
  const EditRoutinePermission = hasPermissions(["routines.edit"]);
  const AddRoutinePermission = hasPermissions(["routines.add"]);
  const [state, dispatch] = React.useReducer(
    routineReducer,
    initalRoutineState
  );

  const handleAddRoutine = () => {
    dispatch({ type: "ADD_ROUTINE" });
  };

  const handleEditRoutine = routineID => {
    dispatch({ type: "EDIT_ROUTINE", payload: { routineID } });
  };

  const handleCancel = () => {
    dispatch({ type: "CLOSE_FORM" });
  };

  return state.showForm === false ? (
    <RoutineServiceContainer
      showTitle={true}
      entityId={facilityID}
      onAddClick={handleAddRoutine}
      onEditClick={handleEditRoutine}
    />
  ) : (AddRoutinePermission || EditRoutinePermission) === true ? (
    <RoutineComposer routineId={state.routineID} onCancel={handleCancel} />
  ) : (
    React.Fragment
  );
};
