import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => {
  return {
    [theme.breakpoints.down("sm")]:
    {
      FacilityOnboard: { display: "grid", gridTemplateColumns: "repeat(1, 100%)", gap:"10px"},
      FacilityName: { gridColumn: 1, gridRow: 2 },
      FacilityLabel: { gridColumn: 1, gridRow: 3 },
      TimeZone: { gridColumn: 1, gridRow: 4 },
      FacilityAddress1: { gridColumn: 1, gridRow: 5 },
      FacilityAddress2: { gridColumn: 1, gridRow: 6 },
      FacilityCity: { gridColumn: 1, gridRow: 7, width: "calc( 60% - 5px )" },
      FacilityState: { gridColumn: 1, gridRow: 7, width: "calc( 18% - 5px )", marginLeft: "calc( 60% + 5px )" },
      FacilityZip: { gridColumn: 1, gridRow: 7, width: "calc( 22% - 10px )", marginLeft: "calc( 78% + 10px )" },
      PrimaryLabel: { gridColumn: 1, gridRow: 8 },
      PrimaryFirstName: { gridColumn: 1, gridRow: 9, width: "calc( 50% - 5px )" },
      PrimaryLastName: { gridColumn: 1, gridRow: 9, width: "calc( 50% - 5px )", marginLeft:"calc( 50% + 5px )" },
      PrimaryAddress1: { gridColumn: 1, gridRow: 10 },
      PrimaryAddress2: { gridColumn: 1, gridRow: 11 },
      PrimaryCity: { gridColumn: 1, gridRow: 12, width: "calc( 60% - 5px )" },
      PrimaryState: { gridColumn: 1, gridRow: 12, width: "calc( 18% - 5px )", marginLeft: "calc( 60% + 5px )" },
      PrimaryZip: { gridColumn: 1, gridRow: 12, width: "calc( 22% - 10px )", marginLeft: "calc( 78% + 10px )" },
      PrimaryPhone1: { gridColumn: 1, gridRow: 13, width: "calc( 50% - 5px )" },
      PrimaryPhone2: { gridColumn: 1, gridRow: 13, width: "calc( 50% - 5px )", marginLeft:"calc( 50% + 5px )" },
      PrimaryEMailAddress: { gridColumn: 1, gridRow: 14 },
      SecondaryLabel: { gridColumn: 1, gridRow: 15 },
      SecondaryFirstName: { gridColumn: 1, gridRow: 16, width: "calc( 50% - 5px )" },
      SecondaryLastName: { gridColumn: 1, gridRow: 16, width: "calc( 50% - 5px )", marginLeft:"calc( 50% + 5px )" },
      SecondaryAddress1: { gridColumn: 1, gridRow: 17 },
      SecondaryAddress2: { gridColumn: 1, gridRow: 18 },
      SecondaryCity: { gridColumn: 1, gridRow: 19, width: "calc( 60% - 5px )" },
      SecondaryState: { gridColumn: 1, gridRow: 19, width: "calc( 18% - 5px )", marginLeft: "calc( 60% + 5px )" },
      SecondaryZip: { gridColumn: 1, gridRow: 19, width: "calc( 22% - 10px )", marginLeft: "calc( 78% + 10px )" },
      SecondaryPhone1: { gridColumn: 1, gridRow: 20, width: "calc( 50% - 5px )" },
      SecondaryPhone2: { gridColumn: 1, gridRow: 20, width: "calc( 50% - 5px )", marginLeft:"calc( 50% + 5px )" },
      SecondaryEMailAddress: { gridColumn: 1, gridRow: 21 },

      ButtonSave: { gridColumn: 1, gridRow: 1, width: "calc( 24% - 5px )", marginLeft:"calc( 76% + 5px )", height:"70%" },
      ButtonCancel: { gridColumn: 1, gridRow: 1, width: "calc( 24% - 5px )", marginLeft:"calc( 52% )", height: "70%"},
    },

    [theme.breakpoints.up("md")]: {
      FacilityOnboard: { display: "grid", gridTemplateColumns: "repeat(2, 48%)", gap:"10px", columnGap: "4%" },
      FacilityName: { gridColumn: 1, gridRow: 1 },
      FacilityLabel: { gridColumn: 1, gridRow: 2 },
      TimeZone: { gridColumn: 1, gridRow: 3 },
      FacilityAddress1: { gridColumn: 1, gridRow: 4 },
      FacilityAddress2: { gridColumn: 1, gridRow: 5 },
      FacilityCity: { gridColumn: 1, gridRow: 6, width: "calc( 60% - 5px )" },
      FacilityState: { gridColumn: 1, gridRow: 6, width: "calc( 18% - 5px )", marginLeft: "calc( 60% + 5px )" },
      FacilityZip: { gridColumn: 1, gridRow: 6, width: "calc( 22% - 10px )", marginLeft: "calc( 78% + 10px )" },
      PrimaryLabel: { gridColumn: 1, gridRow: 7 },
      PrimaryFirstName: { gridColumn: 1, gridRow: 8, width: "calc( 50% - 5px )" },
      PrimaryLastName: { gridColumn: 1, gridRow: 8, width: "calc( 50% - 5px )", marginLeft:"calc( 50% + 5px )" },
      PrimaryAddress1: { gridColumn: 1, gridRow: 9 },
      PrimaryAddress2: { gridColumn: 1, gridRow: 10 },
      PrimaryCity: { gridColumn: 1, gridRow: 11, width: "calc( 60% - 5px )" },
      PrimaryState: { gridColumn: 1, gridRow: 11, width: "calc( 18% - 5px )", marginLeft: "calc( 60% + 5px )" },
      PrimaryZip: { gridColumn: 1, gridRow: 11, width: "calc( 22% - 10px )", marginLeft: "calc( 78% + 10px )" },
      PrimaryPhone1: { gridColumn: 1, gridRow: 12, width: "calc( 50% - 5px )" },
      PrimaryPhone2: { gridColumn: 1, gridRow: 12, width: "calc( 50% - 5px )", marginLeft:"calc( 50% + 5px )" },
      PrimaryEMailAddress: { gridColumn: 1, gridRow: 13 },
      SecondaryLabel: { gridColumn: 2, gridRow: 7 },
      SecondaryFirstName: { gridColumn: 2, gridRow: 8, width: "calc( 50% - 5px )" },
      SecondaryLastName: { gridColumn: 2, gridRow: 8, width: "calc( 50% - 5px )", marginLeft:"calc( 50% + 5px )" },
      SecondaryAddress1: { gridColumn: 2, gridRow: 9 },
      SecondaryAddress2: { gridColumn: 2, gridRow: 10 },
      SecondaryCity: { gridColumn: 2, gridRow: 11, width: "calc( 60% - 5px )" },
      SecondaryState: { gridColumn: 2, gridRow: 11, width: "calc( 18% - 5px )", marginLeft: "calc( 60% + 5px )" },
      SecondaryZip: { gridColumn: 2, gridRow: 11, width: "calc( 22% - 10px )", marginLeft: "calc( 78% + 10px )" },
      SecondaryPhone1: { gridColumn: 2, gridRow: 12, width: "calc( 50% - 5px )" },
      SecondaryPhone2: { gridColumn: 2, gridRow: 12, width: "calc( 50% - 5px )", marginLeft:"calc( 50% + 5px )" },
      SecondaryEMailAddress: { gridColumn: 2, gridRow: 13 },
      ButtonSave: { gridColumn: 2, gridRow: 1, width: "calc( 24% - 5px )", marginLeft:"calc( 76% + 5px )", height:"70%" },
      ButtonCancel: { gridColumn: 2, gridRow: 1, width: "calc( 24% - 5px )", marginLeft:"calc( 52% )", height: "70%"},
    },
  }
});

export default styles;
