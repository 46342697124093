import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";
import useThemeContext from "../../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../../reducers/layout/layoutReducer";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags"
import { Divider, Typography } from "@material-ui/core";


const PlaygroundLandingPage = (props) => {
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;
  const classes = useStyles();
  const { facilityID } = useCurrentFacility();

  const playground      = useFeatureFlag("Playground");
  const jasonArea       = useFeatureFlag("Playground.Jason");
  const DeviceControlV2 = useFeatureFlag("Playground.Jason.DeviceControlV2");


  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);


  if (!playground) return (<></>)
  return (
    <div className={classes.root}>
    <Typography className={classes.title} variant="h4">Playground Landing</Typography>
    <Divider className={classes.titleDivider}/>    
    <>
    {jasonArea && (
      <>
      <div>JASON</div>
        {DeviceControlV2 && (
          <div>D2</div>
        )}
      </>
    )}
    </>
    </div>
  )
};

export default PlaygroundLandingPage;
