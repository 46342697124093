import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  accessDenied: {
    color: "red"
  },
  insufficientPermissions: {
    color: "black",
    marginTop: 20,
    marginBottom: 20,
  },
}));
