import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: { 
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0)
    },
   },
   title: {
    margin: theme.spacing(1),
    color: "primary"
  },
}));