import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  inventoryMaintence: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    fontStyle: "italic",
    width: "calc(100% - 15px)",
    animation: "fadeIn",
    animationDuration: "2s",
  },
}));
