import { orange } from "@material-ui/core/colors";
import { makeStyles, lighten,darken } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root:{
    margin:10,
  },
  innerCard: {
    minWidth:150,
    display:"inline-block",
    marginRight:10,

  },
  title: {
    color:darken(theme.palette.amano.main,0.2),

  },
  titleDivider:{
    marginBottom:10,
  },
  containerCard:{
    marginBottom:10,
  },
  detailTitle: {
    color: theme.palette.warning.main,
  },
  gridListArea: {
    padding: theme.spacing(1),
  },
  gridOverviewArea: {
    padding: theme.spacing(1),
  },
  overviewTitle: {
    fontWeight:"bold",
    color: theme.palette.primary.main,
    display:"inline-block",
    minWidth:80,
    "&.online"  : {color:theme.palette.amano.warnings.good},
    "&.gatesup" : {color:theme.palette.amano.main},
    "&.total"   : {color:theme.palette.amano.active},
    "&.offline" : {color:theme.palette.text.disabled},
  },
  overviewSubTitle: {
    color: theme.palette.text.primary,
    display:"inline-block",
    minWidth:80,
    "&.online"  : {color:theme.palette.amano.warnings.good},
    "&.gatesup" : {color:theme.palette.amano.main},
    "&.total"   : {color:theme.palette.amano.active},
    "&.offline" : {color:theme.palette.text.disabled},
  },
  overviewValue: {
    color: theme.palette.warning.dark,
    float:"right",
    fontSize:"1.2rem",
    lineHeight:"1.2rem",
    fontWeight:600,
  },
  overviewSubValue: {
    color: theme.palette.warning.dark,
    float:"right",
    fontWeight:600,
    "& .offline":{
      fontWeight:400,
      color: lighten(theme.palette.text.disabled,.4),
    },
    "& .frag_1": {
      color: theme.palette.amano.warnings.good,
    }
  },
  bigGap:{
    marginBottom:20,
  },


  cardContent: {
    cursor:"pointer",
    fontSize: 24,
    margin:theme.spacing(-2),
    textAlign:"center",
    color: theme.palette.primary.light,
  },
  cardActions: {
    float:"right",
  },
  deviceList: {
    maxHeight:300,
    overflowX: "auto",
  },
  versionCard: {
    minWidth:150, 
    display:"inline-block", 
    marginRight:5
  },
  versionCardTitle: {
    color: theme.palette.warning.main,
    cursor:"pointer",
  },
  chip: { 

    margin: theme.spacing(.5),
    fontSize: 12,    
    minWidth:150,
    position:"relative",
    display:"inline-block",
    color: theme.palette.text.disabled,
    padding:6,
    paddingBottom:2,
    fontWeight:"bold",
    '&.online': {
      color: theme.palette.primary.dark,
      backgroundColor: lighten(theme.palette.background.default,0),
    },
  },
  chipTitle:{
    fontWeight:"normal",
    fontSize:9,
    overflow:"hidden",
    color:theme.palette.text.disabled,
  },
  doughnutSection: {
    width: "45%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      order: 2,
    },
  },
  barChartSection: {
    width: "55%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      order: 1,
    },
  },
  accordionSummary: {
    width: "100%",
    textAlign: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    fontSize: "large",
  },
  //   doughtnuts: {
  //     width: "32%",
  //     [theme.breakpoints.down("md")]: {
  //       width: "30%",
  //       display: 'flex',
  //       alignItems: 'center',
  //       justifyContent: 'center',
  //     },
  //     [theme.breakpoints.down("sm")]: {
  //       width: "100%",
  //       display: 'flex',
  //       alignItems: 'center',
  //       justifyContent: 'center',
  //       order: 2
  //     }
  //   },
}));
