import Moment from "moment";

export const formatForUpdate = (updates, original) => {
  const start = updates.startDate || original.effectiveStartDate;
  const end = updates.endDate || original.effectiveEndDate;
  const formattedChanges = {
    rateTitle: updates.title || original.rateTitle,
    effectiveStartDate: start,
    effectiveEndDate: end,
    cadence: updates.startDate
      ? createCadence(updates.startDate)
      : original.cadence,
    rateRecords: updates.notes
      ? [{ ...original.rateRecords[0], price: parseInt(updates.notes) }]
      : original.rateRecords,
    entryDuration: (Date.parse(end) - Date.parse(start)) / 1000
  };
  return { ...original, ...formattedChanges };
};

export const validate = values => {
  let errors = null;
  const parsed = parseInt(values.notes);
  if (!values.endDate || !values.startDate) {
    errors = "must have a start and end date";
  } else if (Moment(values.endDate).diff(Moment(values.startDate)) < 0) {
    errors = "end of the event must be before the start";
  } else if (Moment(values.startDate).diff(Moment()) < 0) {
    errors = "event cannot start in the past";
  } else if (!values.notes) {
    errors = "event must have a rate";
  } else if (parsed < 0) {
    errors = "rate cannot be negative";
  } else if (isNaN(parsed)) {
    errors = "rate must be a valid number";
  } else if (!values.title || values.title === "") {
    errors = "event must have a title";
  }
  return errors;
};

export const createCalendarData = data => {
  return data.map(item => {
    return {
      title: item.rateTitle,
      startDate: Moment.tz(item.effectiveStartDate, "UTC")
        .local()
        .toDate(),
      endDate: item.effectiveEndDate
        ? Moment.tz(item.effectiveEndDate, "UTC")
          .local()
          .toDate()
        : Moment.tz()
          .local()
          .toDate(),
      id: item.entityRateID,
      notes: item.rateRecords === null ? null : item.rateRecords[0].price
    };
  });
};

export const formatForValidate = values => {
  return {
    endDate: values.effectiveEndDate,
    startDate: values.effectiveStartDate,
    title: values.rateTitle,
    notes: values.rateRecords[0].price
  };
};

export const validateRate = value => {
  let errors = null;
  const parsed = parseInt(value);
  if (parsed < 0) {
    errors = "rate cannot be negative";
  }
  if (isNaN(parsed)) {
    errors = "rate must be a valid number";
  }
  return errors;
};

export const validateTitle = title => {
  let error = null;
  if (title === "" || title === null || !title.replace(/\s/g, "").length) {
    error = "event must have a title";
  } else if (title.length > 50) {
    error = "title must be less than 50 characters";
  } else if (!title.length) {
    error = "event must have a title";
  }
  return error;
};

export const createCadence = startDate => {
  let date = startDate;
  if (date instanceof Moment) {
    date = date._d;
  }
  return `${date.getMinutes()} ${date.getHours()} ${date.getDate()} ${date.getMonth()} ${date.getDay()}`;
};
