import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useThemeContext from "../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";
import useCurrentFacility from "../../hooks/useCurrentFacility";

import AdminDashboard from "./Dashboard/index.js";

const AdministrationPage = props => {
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;
  
  const { facilityID } = useCurrentFacility();
  const isAdmin = useSelector(state => state?.user?.isAdmin);

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  if (!isAdmin) return <></>;
  return <AdminDashboard entityID={facilityID} />;
};

export default AdministrationPage;
