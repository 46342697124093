import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backButtonPlaceholder: {
    width: theme.spacing(8.75),
    height: theme.spacing(4),
  },
  backButton: {
    width: theme.spacing(8.75),
    height: theme.spacing(4),
  },
}));

export default useStyles;
