import React from "react";
import {useSelector} from "react-redux";
import {useStyles} from "./styles";
import {Box, Typography, Divider} from "@material-ui/core";
import DeployableScripts from "../../../components/Administration/DeployableScripts";
import DeployableScriptsProvider from "../../../components/Administration/DeployableScripts/DeployableScriptsProvider";

const DeployableScriptsPage = () => {
  const classes = useStyles();
  const isAdmin = useSelector(state => state?.user?.isAdmin);

  if (!isAdmin) return <></>;

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h4">Deployable Scripts</Typography>
      <Divider className={classes.titleDivider}/>
      <Box display="flex" justifyContent="center" alignItems="center">
        <DeployableScriptsProvider>
          <DeployableScripts></DeployableScripts>
        </DeployableScriptsProvider>
      </Box>
    </div>
  );
};

export default DeployableScriptsPage;
