import React, { useEffect } from "react";
import useThemeContext from "../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";
import TermsAndConditions from "../../components/Modals/TermsAndConditions";
import { Typography } from "@material-ui/core";
import { Divider } from "@material-ui/core";

const GeneralPage = props => {
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: "lg" });
  }, [layoutDispatch]);

  return (
    <div style={{marginLeft:40, marginTop:20}}>
      <Typography variant="h5" color="primary">General</Typography>
      <Divider style={{marginBottom:20}}/>
      <TermsAndConditions />
    </div>
  );
};

export default GeneralPage;