import clsx from "clsx";
import React, { useEffect, useState, useLayoutEffect, useCallback } from "react";
import { useStyles } from "./styles";
import { Grid, CircularProgress } from "@material-ui/core";
import FPAPayment from "../../components/FlexPayment";
import GenericError from "../../components/FlexPayment/GenericError";
import FPAEmailValidationForm from "../../components/FlexPayment/FPAEmailValidateForm";
import { useParams, useLocation, useHistory } from "react-router-dom";
import AuthService from "../../services/AuthService";
import apiClient, { setAuthentication as setApiAuth } from "../../auth/apiClient";
import { setAuthenticationState } from "../../reducers/auth/authReducer";
import useAuthContext from "../../hooks/useAuthContext";
import { useTheme } from "@material-ui/styles";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";

const authService = new AuthService(apiClient);

const FPAPage = ({ }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { accessHolderId, } = useParams();

  const [resultsFromQuery, setResultsFromQuery] = useState({
    amiReferenceId: null,
    sessionId: null
  });
  const [fundDetails, setfundDetails] = useState({});
  const [errMess, setErrMess] = useState();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { authReducer } = useAuthContext();
  const [, authDispatch] = authReducer;
  const location = useLocation();
  const history = useHistory();
  const enqueueSnackbar = useEnqueueSnackbar();

  const getAuth =
    async () => {
      try {
        var appClientToken = await authService.getMobilePayAuthToken();
        return appClientToken;
      } catch (error) {
        console.error("Error Authenticating", error);
      }
    };

  useEffect(() => {
    if (accessHolderId != sessionStorage.getItem('ahID')) {
      sessionStorage.removeItem('emailVerified');
      let queryParams = new URLSearchParams(location.search);
      queryParams.delete("referenceid");
      queryParams.delete("sessionId");
      queryParams.delete("message");
      history.replace({ search: queryParams.toString() });
    }
    sessionStorage.setItem('ahID', accessHolderId);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      getAuth().then(appClientToken => {
        if (appClientToken !== "") {
          setApiAuth(appClientToken);
          authDispatch({
            type: setAuthenticationState,
            payload: { properties: [] },
          });
          setIsAuthenticated(true);
        }
      })
    }
  }, [authDispatch]);

  useLayoutEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.level1;
  }, []);

  useEffect(() => {
    try {
      let queryParams = new URLSearchParams(location.search);
      if(queryParams.get("message") === "fail"){
        showError("Transaction Declined")
      }
      if (queryParams.has("message") && queryParams.get("message").toLowerCase() !="success") {
        const errM = queryParams.get("message");
        queryParams.delete("referenceid");
        queryParams.delete("sessionId");
        queryParams.delete("MerchantRef");
        queryParams.delete("CardExpiry");
        queryParams.delete("message");
        queryParams.delete("AuthorisationCode");
        queryParams.delete("CardType");
        queryParams.delete("TokenNo");
        queryParams.delete("3DSIndicator");
        queryParams.delete("SCATransRef");
        queryParams.delete("TxID");
        history.replace({ search: queryParams.toString() });
        setErrMess(errM);
        return;
      }

      let tmpValues = {
        amiReferenceId: "",
        sessionId: ""
      };
      if (queryParams.has("referenceid")) {
        tmpValues["amiReferenceId"] = queryParams.get("referenceid");
        setErrMess("ok");
      }
      if (queryParams.has("MerchantRef")) {
        tmpValues["amiReferenceId"] = queryParams.get("MerchantRef");
        setErrMess("ok");
      }
      if (queryParams.has("sessionId")) {
        tmpValues["sessionId"] = queryParams.get("sessionId");
      }
      setResultsFromQuery(tmpValues);
    } catch (error) {
      console.error("Error getting query strings")
    }

  }, [location]);

  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      tag: "fpaPlanetPaymentError"
    });
  };

  const handleEmailVerified = (values) => {
    if (values != null) {
      sessionStorage.setItem('emailVerified', values.email);
      sessionStorage.setItem('ahID', accessHolderId);
      setfundDetails(values);
      return;
    }
    sessionStorage.removeItem('emailVerified');
    setErrMess("");
  };

  return (
    <div className={clsx(classes.root)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img className={classes.logo} alt="company logo" src="/images/logos/a1-logo-stacked-dark.svg" />
        </Grid>
      </Grid>

      <Grid container spacing={2} className={clsx(classes.contentContainer)} >
        {!accessHolderId ? (
          <GenericError message="Please use the link that was emailed to you"></GenericError>
        ) : (
          <>
            {!isAuthenticated ? (
              <CircularProgress size={60} />
            ) : (
              <>
                {
                  !sessionStorage.getItem('emailVerified') ? (
                    <FPAEmailValidationForm
                      accessHolderId={accessHolderId}
                      onSubmit={handleEmailVerified}
                      userErrMessage={errMess}
                    />
                  ) : (
                    <FPAPayment
                      accessHolderId={accessHolderId}
                      resultsFromQuery={resultsFromQuery}
                      verifiedEmail={sessionStorage.getItem('emailVerified')}
                    />
                  )}
              </>
            )}
          </>
        )}
      </Grid>
    </div>
  );
};

export default FPAPage;