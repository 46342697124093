import React from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import useFormState from "../../../hooks/useFormState";
import { validateRate, validateTitle } from "../utilities";
import Typography from "@material-ui/core/Typography";
import useHasPermissions from "../../../hooks/useHasPermissions";
import { useStyles } from "./styles";

const TextEditor = props => {
  const classes = useStyles();
  const {
    addError,
    removeError,
    formErrors,
    isEditingStarted,
    setIsEditingStarted
  } = useFormState();
  const { hasPermissions } = useHasPermissions();
  const lacksPermission = !hasPermissions(["settings.rates.write"]);
  const [isExistingApplication] = React.useState(props.value !== undefined);

  const dateInPastError =
    "You cannot create or update events that have already begun or have passed";

  const handleRateChange = e => {
    if (!isEditingStarted) {
      setIsEditingStarted(true);
    }
    const error = validateRate(e.target.value);
    if (error && formErrors.rate === "") {
      addError("rate", error);
    } else if (!error && formErrors.rate !== "") {
      removeError("rate");
    }
    props.onValueChange(e.target.value);
  };

  const handleTitleChange = e => {
    if (!isEditingStarted) {
      setIsEditingStarted(true);
    }
    const error = validateTitle(e.target.value);
    if (error && formErrors.title === "") {
      addError("title", error);
    } else if (!error && formErrors.title !== "") {
      removeError("title");
    }
    props.onValueChange(e.target.value);
  };
  return (
    <>
      {props.placeholder === "Notes" && (
        <TextField
          disabled={lacksPermission || formErrors.isPast !== ""}
          error={formErrors.rate && true}
          value={props.value}
          variant="outlined"
          type="number"
          placeholder="Enter A Rate"
          required
          name="notes"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
          onChange={handleRateChange}
          helperText={formErrors.rate || null}
        />
      )}

      {props.placeholder === "Title" && (
        <>
          {(lacksPermission && (
            <Typography component="h5" className={classes.disabledText}>
              You do not have access to create or alter events
            </Typography>
          )) ||
            (formErrors.isPast === dateInPastError && (
              <Typography component="h5" className={classes.disabledText}>
                {formErrors.isPast}
              </Typography>
            ))}
          <TextField
            disabled={
              lacksPermission ||
              formErrors.isPast !== "" ||
              isExistingApplication
            }
            required
            value={props.value || ""}
            helperText={formErrors.title || null}
            error={formErrors.title && true}
            placeholder="Event Title"
            variant="outlined"
            fullWidth
            type="text"
            name="title"
            onChange={handleTitleChange}
          />
        </>
      )}
    </>
  );
};

export default TextEditor;
