import React from "react";
import { DateTimePicker } from "@material-ui/pickers";
import { useStyles } from "./styles";
import useHasPermissions from "../../../hooks/useHasPermissions";
import useFormState from "../../../hooks/useFormState";

const DateEditor = props => {
  const { formErrors, isEditingStarted, setIsEditingStarted } = useFormState();
  const { hasPermissions } = useHasPermissions();
  const lacksPermission = !hasPermissions(["settings.rates.write"]);
  const classes = useStyles();

  const handleChange = value => {
    if (!isEditingStarted) {
      setIsEditingStarted(true);
    }
    props.onValueChange(value);
  };

  return (
    <DateTimePicker
      disabled={lacksPermission || formErrors.isPast !== ""}
      inputVariant="outlined"
      className={classes.root}
      disablePast
      value={props.value}
      autoOk
      onChange={handleChange}
    />
  );
};

export default DateEditor;
