import { makeStyles,lighten,darken } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin:10,
  },
  title: {
    color:darken(theme.palette.amano.main,0.2),
  }
}));
