import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import useThemeContext from "../../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../../reducers/layout/layoutReducer";
import CredentialInventory from "../../../components/Credentials/CredentialInventory";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Typography } from "@material-ui/core";
import { useStyles } from "./style";

const CredentialInventoryPage = (props) => {
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;
  const { parkingInventory } = useFlags();

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  return (
    <Switch>
      {parkingInventory && (
        <Route exact path="/inventory" component={CredentialInventory} />
      )}
      {!parkingInventory && (
        <Route
          exact
          path="/inventory"
          component={CredentialInventoryMaintenancePage}
        />
      )}
    </Switch>
  );
};

const CredentialInventoryMaintenancePage = () => {
  const classes = useStyles();
  return (
    <Typography
      className={classes.inventoryMaintence}
      data-testid="inventory-maintenance"
    >
      This feature is undergoing a few improvements and will be available soon.
    </Typography>
  );
};

export default CredentialInventoryPage;
