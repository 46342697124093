import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  spacer: {
    margin: theme.spacing(1)
  },
  fieldHeader: {
    fontWeight: 600,
    fontSize: "1.4em"
  },
  rateHeader: {
    fontWeight: 600,
    fontSize: "1.4em",
    marginTop: theme.spacing(3)
  }
}));
