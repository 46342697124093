import React, { useEffect } from "react";
import useThemeContext from "../../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../../reducers/layout/layoutReducer";
import { Grid } from "@material-ui/core";
import { useStyles } from "./styles";
import Import from "../../../components/Import/Import";

const ImportPage = props => {
  const classes = useStyles();
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: "lg" });
  }, [layoutDispatch]);

  return <>
    <Grid container className={classes.root}>
      <Grid className={classes.contentContainer} item xs={12} md={12} lg={12}>
        <Import/>
      </Grid>
    </Grid>
</>
};

export default ImportPage;