import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {},

    stepper: {
        "& .MuiStepIcon-root.MuiStepIcon-completed": {
            color: "#00a614"
        }
    },
    buttonGrid: {
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(2),
      },
    },
    buttonDiv: { 
      float: "right", 
      [theme.breakpoints.down("md")]: {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2)
      },
    },
    backButton: { 
      marginRight: theme.spacing(3),
      [theme.breakpoints.up("md")]: {
          minWidth: "50px",
          lineHeight: 1.5  
      },
      [theme.breakpoints.down("md")]: {
          minWidth: "100px",
          lineHeight: 3
      },
    },
    nextButton: {
      [theme.breakpoints.up("md")]: {
          minWidth: "50px",
          lineHeight: 1.5
      },
      [theme.breakpoints.down("md")]: {
          minWidth: "100px",
          lineHeight: 3
      },
    }
}));