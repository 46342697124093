import React, { useEffect } from "react";
import ReportRenderer from "../../components/Reports";
import { useParams } from "react-router-dom";
import useThemeContext from "../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";
import { useStyles } from "./styles";
import useHasPermissions from "../../hooks/useHasPermissions";
import { useFeatureFlag } from "../../hooks/useFeatureFlags";

const ReportsPage = () => {
  const classes = useStyles();
  const { reportName } = useParams();
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;
  const { hasPermissions } = useHasPermissions();
  const hasReportPermission = hasPermissions([reportName]);
  const FPAFeatureFlag = useFeatureFlag("Flexible Parking Account");
  const sharedAcctFeatureFlag = useFeatureFlag("Shared Accounts");
  const hotelIntegration = useFeatureFlag("Hotel Integrations");

  const featureFlagPermission = (name) => {
    switch (name) {
      case "HotelAccessHolders":
        return hotelIntegration;
      case "HotelCharges":
        return hotelIntegration;
      case "FPAStatement":
        return FPAFeatureFlag;
      case "SharedAccountActivity":
        return sharedAcctFeatureFlag;
      default:
        return true;
    }
  };

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  return (
    <>
      {!featureFlagPermission(reportName) && <h1>{reportName} is disabled</h1>}
      {featureFlagPermission(reportName) && hasReportPermission === false && (
        <h1>You do not have permission to view {reportName}</h1>
      )}
      {hasReportPermission === true && featureFlagPermission(reportName) && (
        <ReportRenderer
          className={classes.reportViewer}
          reportName={reportName}
        />
      )}
    </>
  );
};

export default ReportsPage;
