import React, { useEffect, useState } from "react";
import useThemeContext from "../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";
import styles from "./styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import moment from "moment-timezone";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import MuiPhoneNumber from "material-ui-phone-number";

const OnboardingPage = (props) => {
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;
  const [data, setData] = useState({
    FacilityName: "",
    TimeZone: moment.tz.guess(),
    FacilityAddress1: "",
    FacilityAddress2: "",
    FacilityCity: "",
    FacilityState: "",
    FacilityZip: "",
    PrimaryFirstName: "",
    PrimaryLastName: "",
    PrimaryAddress1: "",
    PrimaryAddress2: "",
    PrimaryCity: "",
    PrimaryState: "",
    PrimaryZip: "",
    PrimaryPhone1: "",
    PrimaryPhone2: "",
    PrimaryEMailAddress: "",
    SecondaryFirstName: "",
    SecondaryLastName: "",
    SecondaryAddress1: "",
    SecondaryAddress2: "",
    SecondaryCity: "",
    SecondaryState: "",
    SecondaryZip: "",
    SecondaryPhone1: "",
    SecondaryPhone2: "",
    SecondaryEMailAddress: "",
  });

  const style = styles();

  function actionSave(data) {
    // send data to endpoint to save
  }

  function actionCancel() {}

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: "lg" });
  }, [layoutDispatch]);

  const inputLabelProps = { shrink: true };
  var zoneList = moment.tz.zonesForCountry('US');

  var ValidationSchema = Yup.object().shape({
    FacilityName: Yup.string()
      .min(3)
      .max(100, "Cannot exceed 100 characters")
      .required("Required"),
    TimeZone: Yup.string(),
    FacilityAddress1: Yup.string().required("Required"),
    FacilityAddress2: Yup.string(),
    FacilityCity: Yup.string().required("Required"),
    FacilityState: Yup.string().required("Required"),
    FacilityZip: Yup.string().required("Required"),
    PrimaryFirstName: Yup.string().required("Required"),
    PrimaryLastName: Yup.string().required("Required"),
    PrimaryAddress1: Yup.string().required("Required"),
    PrimaryAddress2: Yup.string(),
    PrimaryCity: Yup.string().required("Required"),
    PrimaryState: Yup.string().required("Required"),
    PrimaryZip: Yup.string().required("Required"),
    PrimaryPhone1: Yup.string().required("Required"),
    PrimaryPhone2: Yup.string(),
    PrimaryEMailAddress: Yup.string().email("Invalid email").required("Required"),
    SecondaryFirstName: Yup.string(),
    SecondaryLastName: Yup.string(),
    SecondaryAddress1: Yup.string(),
    SecondaryAddress2: Yup.string(),
    SecondaryCity: Yup.string(),
    SecondaryState: Yup.string(),
    SecondaryZip: Yup.string(),
    SecondaryPhone1: Yup.string(),
    SecondaryPhone2: Yup.string(),
    SecondaryEMailAddress: Yup.string().email("Invalid email"),
  });

  var zoneOptions = [];
  for (var i = 0; i < zoneList.length; i++) {
    zoneOptions.push(
      <MenuItem value={zoneList[i]} key={zoneList[i]}>
        {zoneList[i].replaceAll("_", " ")}
      </MenuItem>
    );
  }

  function phonefield(name, label, props) {
    return (
      <Field
        validateOnBlur
        validateOnChangename={name}
        render={({ field, form }) => (
          <MuiPhoneNumber
            className={style[name]}
            defaultCountry={"us"}
            regions={["europe", "north-america"]}
            onChange={(e) => props.setFieldValue(name, e)}
            label={label}
            data-id={name}
            disableAreaCodes
            disableCountryCode
            variant="outlined"
            error={Boolean(form.errors[name] && form.touched[name])}
            helperText={form.errors[name]}
          />
        )}
      />
    );
  }

  function textfield(name, label, props) {
    return (
      <Field
        validateOnBlur
        validateOnChange
        name={name}
        render={({ field, form }) => (
          <TextField
            label={label}
            type="text"
            name={name}
            onChange={(e) => props.setFieldValue(name, e.target.value)}
            className={style[name]}
            variant="outlined"
            InputLabelProps={inputLabelProps}
            error={Boolean(form.errors[name] && form.touched[name])}
            helperText={form.errors[name]}
          />
        )}
      />
    );
  }

  return (
    <>
      <Formik
        onSubmit={async (values) => {
          await actionSave(values);
        }}
        enableReinitialize
        validateOnChange={false}
        initialValues={data}
        validationSchema={ValidationSchema}
      >
        {(formikProps) => (
          <Form>
            <div>
              <div className={style.FacilityOnboard}>
                {textfield("FacilityName", "Facility Name", formikProps)}

                <h2 className={style.FacilityLabel}>Facility Address</h2>
                <FormControl variant="outlined" className={style.TimeZone}>
                  <InputLabel>Time Zone *</InputLabel>
                  <Field
                    id="timezones"
                    data-id="timezone-select"
                    component={TextField}
                    labelId="TimeZoneLabel"
                    name="TimeZone"
                    label="Time Zone *"
                    variant="outlined"
                    defaultValue=""
                    value={data.TimeZone ?? ""}
                    select
                    SelectProps={{
                      SelectDisplayProps: { "data-testid": "timezone-select" },
                    }}
                    className={style.timeZone}
                    onChange={(e, selected) => {
                      data.TimeZone = selected.props.value ?? ""
                      const {setFieldValue} = formikProps;
                      setFieldValue("TimeZone", selected.props.value);
                    }}
                  >
                    {zoneOptions}
                  </Field>
                </FormControl>

                {textfield("FacilityAddress1", "Address 1 *", formikProps)}
                {textfield("FacilityAddress2", "Address 2", formikProps)}
                {textfield("FacilityCity", "City *", formikProps)}
                {textfield("FacilityState", "State *", formikProps)}
                {textfield("FacilityZip", "Zip *", formikProps)}

                <h2 className={style.PrimaryLabel}>Primary Contact</h2>
                {textfield("PrimaryFirstName", "First Name *", formikProps)}
                {textfield("PrimaryLastName", "Last Name *", formikProps)}
                {textfield("PrimaryAddress1", "Address 1 *", formikProps)}
                {textfield("PrimaryAddress2", "Address 2", formikProps)}
                {textfield("PrimaryCity", "City *", formikProps)}
                {textfield("PrimaryState", "State *", formikProps)}
                {textfield("PrimaryZip", "Zip *", formikProps)}
                {phonefield("PrimaryPhone1", "Phone 1 *", formikProps)}
                {phonefield("PrimaryPhone2", "Phone 2", formikProps)}
                {textfield(
                  "PrimaryEMailAddress",
                  "eMail Address *",
                  formikProps
                )}

                <h2 className={style.SecondaryLabel}>Secondary Contact</h2>
                {textfield("SecondaryFirstName", "First Name *", formikProps)}
                {textfield("SecondaryLastName", "Last Name *", formikProps)}
                {textfield("SecondaryAddress1", "Address 1 *", formikProps)}
                {textfield("SecondaryAddress2", "Address 2", formikProps)}
                {textfield("SecondaryCity", "City *", formikProps)}
                {textfield("SecondaryState", "State *", formikProps)}
                {textfield("SecondaryZip", "Zip *", formikProps)}
                {phonefield("SecondaryPhone1", "Phone 1 *", formikProps)}
                {phonefield("SecondaryPhone2", "Phone 2", formikProps)}
                {textfield(
                  "SecondaryEMailAddress",
                  "eMail Address *",
                  formikProps
                )}

                <Button
                  key="savebutton"
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={style.ButtonSave}
                >
                  Save
                </Button>
                <Button
                  key="cancelbutton"
                  variant="contained"
                  color="secondary"
                  onClick={actionCancel}
                  className={style.ButtonCancel}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default OnboardingPage;
