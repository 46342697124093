import { makeStyles,lighten,darken } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin:10,
  },
  title: {
    color:darken(theme.palette.amano.main,0.2),
  },
  titleDivider:{
    marginBottom:10,
    marginTop:4,
  },

  cardAction:{
    float:"right",
    color:lighten(theme.palette.text.disabled,.7),
  },

  cardCountContent:{
    marginTop:5,
    textAlign:"center",
    color:"orange",
    fontSize:"3rem",
  }
}));
