import React from "react";
import PropTypes from "prop-types";

/* Components */
import {Button} from "@material-ui/core";

/* State */
import {useSelector} from "react-redux";

/* Style */
import useStyles from "./styles";

const NextButton = ({step, onClick}) => {
  const classes = useStyles();
  const scannedCredential = useSelector((state) => state.shiftSession.transaction.credential);
  const selectedOffer = useSelector((state) => state.shiftSession.transaction.selectedOffer);
  const validationShowNext = useSelector((state) => state.shiftSession.validationNavigation?.enableNext);

  return (
    <>
      {(() => {
        switch (step) {
          case "scanCredential":
            return !scannedCredential ? (
              <div className={classes.nextButtonPlaceholder}/>
            ) : (
              <Button
                className={classes.nextButton}
                variant="contained"
                color="primary"
                data-testid="nextButton"
                size="small"
                onClick={() => onClick("payment")}
              >
                Next
              </Button>
            );
          case "selectOffer":
            return !selectedOffer ? (
              <div className={classes.nextButtonPlaceholder}/>
            ) : (
              <Button
                className={classes.nextButton}
                variant="contained"
                color="primary"
                data-testid="nextButton"
                size="small"
                onClick={() => onClick("payment")}
              >
                Next
              </Button>
            );
          case "scanDiscounts":
            return (
              <Button
                className={classes.nextButton}
                variant="contained"
                color="primary"
                size="small"
                disabled={validationShowNext === false}
                data-testid="nextButton"
                onClick={() => onClick("payment")}
              >
                Next
              </Button>
            );
          default:
            return <div className={classes.nextButtonPlaceholder}/>;
        }
      })()}
    </>
  );
};

NextButton.defaultProps = {
  onClick: () => {
  },
};

NextButton.propTypes = {
  step: PropTypes.string,
  onClick: PropTypes.func,
};

export default NextButton;
