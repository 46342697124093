import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.level1,
    margin: "auto 20px",
    flexGrow: "1",
  },
  logo: {
    display: "block",
    padding: "2rem 0 2rem 0",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 100,
  },
  contentContainer: {
    display: "flex",
    justifyContent: "center",
  },
  loadingSpinner: {
    display: "flex",
    justifyContent: "center",
  },
}));
